import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { FetchError, FetchSuccess } from '../types';
import { FetchGetId, FetchUpdate, getMessageInError } from '../hooks/fetch';
import { TemplateField, TemplateId, useTemplatesId, useTemplatesUpdate } from '../hooks/templates';

export interface TemplatesUpdateParams {
  name: string;
  status: boolean;
  banner: File | UploadFile | null;
  commodity: number;
  customers: number[];
  templateFields: TemplateField[];
}

export type ValuesUpdateChangeType = { name: string; } | { customers: number[]; } | { commodity: number; }
  | { status: boolean; } | { banner: File | UploadFile | null; } | { templateFields: TemplateField[]; }

interface TemplatesUpdateContext {
  onSave: () => void;
  values: TemplatesUpdateParams;
  setValue: (value: ValuesUpdateChangeType) => void;
  isDisabled: boolean;
  templateId: FetchGetId<TemplateId> | null;
  templateUpdate: FetchUpdate<FetchSuccess, FetchError, FormData> | null;
}

export const initialValues: TemplatesUpdateParams = {
  name: '',
  customers: [],
  commodity: 0,
  status: false,
  banner: null,
  templateFields: [],
};

const defaultValue: TemplatesUpdateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  templateId: null,
  templateUpdate: null,
};

export const TemplatesUpdateContext = createContext<TemplatesUpdateContext>(defaultValue);
interface TemplatesUpdateProviderProps {
  children: React.ReactNode;
}
const TemplatesUpdateProvider: React.FC<TemplatesUpdateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const templateId = useTemplatesId();
  const templateUpdate = useTemplatesUpdate();

  const { id = '' } = useParams<{ id: string; }>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<TemplatesUpdateParams>(initialValues);
  const handleValuesChange = (value: ValuesUpdateChangeType) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    if (id) {
      templateId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (templateId.data && !templateId.error) {
      setValues({
        name: templateId.data.name,
        commodity: templateId.data.commodity?.id || 0,
        customers: templateId.data.customers.map((customer) => customer.id),
        status: templateId.data.status,
        banner: templateId.data.banner ? {
          uid: templateId.data.banner.id.toString(),
          name: templateId.data.banner.fileName,
          fileName: templateId.data.banner.fileName,
          url: templateId.data.banner.url,
        } : null,
        templateFields: templateId.data.templateFields.length ? templateId.data.templateFields
          .sort((prev, next) => prev.position < next.position ? -1 : 1)
          .map((field) => ({
            ...field, value: field.value.split(',').map((value, i) => ({ id: i, value })),
          })) : [],
      });
    }
  }, [templateId.data]);

  useEffect(() => {
    if (templateId.error) {
      message.error(getMessageInError(templateId.error));
      templateId.clearError();
    }
  }, [templateId.error]);

  useEffect(() => {
    let isValid = true;

    if (values.templateFields.length > 0) {
      isValid = values.templateFields.every((field) => field?.name?.trim() !== '')
      && values.templateFields.every((field) => (
        field.type !== 'select' || field.value.every((value) => value.value)
      ));
    }

    isValid = !(isValid && values.name?.trim() !== '');
    setIsDisabled(isValid);
  }, [values.name, values.templateFields]);

  const onSave = () => {
    if (!isDisabled) {
      const formData = new FormData();

      formData.append('name', values.name);
      formData.append('status', `${values.status}`);
      values.templateFields.forEach((x) => {
        const e = x;

        e.name = x.name?.trim();
      });
      values.templateFields.forEach(({ name, value, type, required }, position) => {
        if (name && name.trim() !== '') {
          const fieldData = {
            name,
            type,
            value: type === 'select' || type === 'grading'
              ? value.map((option) => option.value).join(',')
              : {},
            required,
            position,
          };

          formData.append('templateFields[]', JSON.stringify(fieldData));
        }
      });

      formData.append('commodity', `${values.commodity || 0}`);

      if (values.customers.length) {
        values.customers.forEach((customer) => {
          formData.append('customers[]', `${customer}`);
        });
      } else {
        formData.append('customers[]', '');
      }

      if (values.banner?.name !== templateId.data?.banner?.fileName) {
        formData.append('banner', values.banner as File);
      } else if (!values.banner) {
        formData.append('banner', '');
      }

      templateUpdate.fetch(formData, id);
    }
  };

  useEffect(() => {
    if (templateUpdate.data && !templateUpdate.error) {
      message.success('Updated!');
      navigate('/templates', { replace: true });
      setValues(initialValues);
    }
  }, [templateUpdate.data]);

  useEffect(() => {
    if (templateUpdate.error) {
      message.error(getMessageInError(templateUpdate.error));
      templateUpdate.clearError();
    }
  }, [templateUpdate.error]);

  return (
    <TemplatesUpdateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        templateId,
        templateUpdate,
      }}
    >
      {children}
    </TemplatesUpdateContext.Provider>
  );
};

export default TemplatesUpdateProvider;

export const useContextTemplateUpdate = (): TemplatesUpdateContext => useContext(TemplatesUpdateContext);
