import React from 'react';
import { Layout } from 'antd';

import SiderCommon from './Sider';
import styles from './index.module.less';

interface MainProps {
  children: React.ReactNode;
}
const Main: React.FC<MainProps> = ({ children }): JSX.Element => (
  <Layout className={styles.layout}>
    <SiderCommon />
    {children}
  </Layout>
);

export default Main;
