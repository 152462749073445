import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchSuccess } from '../types';
import { useReportTemplateGetId, useReportTemplateUpdate } from '../hooks/reportTemplate';
import { DefaultFetchError, FetchGetId, FetchUpdate, getMessageInError } from '../hooks/fetch';
import { QcReportsId, QcReportTemplate, TemplateFieldValue, useQcReportsId } from '../hooks/qcReports';

export interface ReportImages {
  created: string;
  entity: string;
  fileName: string;
  fileSize: number;
  id: number;
  mimeType: string;
  originalName: string;
  timeStampString: string;
  url:string;
}

interface ReportTemplateUpdateParams {
  galleryImages: ReportImages[];
  report: number;
  images: UploadFile[];
  template: number;
  oldImages: ReportImages[];
  reportTemplateFields: TemplateFieldValue[];
}

type ValuesChangeType = { template: number; } | { reportTemplateFields: TemplateFieldValue[]; }
  | { images: UploadFile[]; } | { oldImages: ReportImages[]; } | { galleryImages: ReportImages[]; };

interface QcReportUpdateTemplateContext {
  onSave: () => void;
  values: ReportTemplateUpdateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  qcReportsId: FetchGetId<QcReportsId> | null;
  setIsDisabled: (disabled: boolean) => void;
  setIsRouteToHome: (isRouteToHome: boolean) => void;
  reportTemplateGetId: FetchGetId<QcReportTemplate> | null;
  reportTemplateUpdate: FetchUpdate<FetchSuccess, DefaultFetchError, FormData> | null;
}

export const initialValues: ReportTemplateUpdateParams = {
  report: 0,
  images: [],
  template: 0,
  oldImages: [],
  reportTemplateFields: [],
  galleryImages: [],
};

const defaultValue: QcReportUpdateTemplateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  qcReportsId: null,
  setIsDisabled: () => undefined,
  reportTemplateGetId: null,
  reportTemplateUpdate: null,
  setIsRouteToHome: () => undefined,
};

export const QcReportUpdateTemplateContext = createContext<QcReportUpdateTemplateContext>(defaultValue);
interface QcReportUpdateTemplateProviderProps {
  children: React.ReactNode;
}
const QcReportUpdateTemplateProvider: React.FC<QcReportUpdateTemplateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const qcReportsId = useQcReportsId();
  const reportTemplateUpdate = useReportTemplateUpdate();
  const reportTemplateGetId = useReportTemplateGetId();
  const { id: reportId = 0, templateId = 0 } = useParams<{ id: string; templateId: string; }>();

  const [values, setValues] = useState<ReportTemplateUpdateParams>({
    ...initialValues,
    report: +reportId,
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isRouteToHome, setIsRouteToHome] = useState<boolean>(true);

  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  const onSave = () => {
    if (!isDisabled) {
      const formData = new FormData();

      values.images.map(({ originFileObj }) => originFileObj).forEach((image) => {
        if (image) {
          formData.append('images', image as File);
        }
      });

      if (values.oldImages.length) {
        values.oldImages.forEach((image) => {
          formData.append('oldImages[]', image.id.toString());
        });
      } else {
        formData.append('oldImages[]', '0');
      }

      formData.append('report', values.report.toString());
      formData.append('template', values.template.toString());
      formData.append('reportTemplateFields', JSON.stringify(values.reportTemplateFields));

      if (values.galleryImages.length) {
        formData.append('galleryImages', JSON.stringify(values.galleryImages.map((el) => el.id)));
      } else {
        formData.append('galleryImages', '[]');
      }

      reportTemplateUpdate.fetch(formData, templateId);
    }
  };

  useEffect(() => {
    if (reportTemplateUpdate.data && !reportTemplateUpdate.error && isRouteToHome) {
      navigate(`/reports/qc/${reportId}`, { replace: true });
    }
    setIsRouteToHome(true);
  }, [reportTemplateUpdate.data]);

  useEffect(() => {
    if (reportTemplateUpdate.error) {
      message.error(getMessageInError(reportTemplateUpdate.error));
      reportTemplateUpdate.clearError();
    }
  }, [reportTemplateUpdate.error]);

  useEffect(() => {
    if (templateId) {
      reportTemplateGetId.fetch(undefined, templateId);
    }
  }, [templateId]);

  useEffect(() => {
    if (reportTemplateGetId.data && !reportTemplateGetId.error) {
      setValues({
        ...values,
        template: reportTemplateGetId.data.template.id,
        galleryImages: reportTemplateGetId.data.images.filter((el) => el.entity === 'gallery'),
        oldImages: reportTemplateGetId.data.images,
        reportTemplateFields: reportTemplateGetId.data.reportTemplateFields
          .map((field) => ({ id: field.templateField.id, value: field.value })),
      });
    }
  }, [reportTemplateGetId.data]);

  useEffect(() => {
    if (reportTemplateGetId.error) {
      message.error(getMessageInError(reportTemplateGetId.error));
      reportTemplateGetId.clearError();
    }
  }, [reportTemplateGetId.error]);

  useEffect(() => {
    if (reportId) {
      qcReportsId.fetch(undefined, reportId);
    }
  }, [reportId]);

  useEffect(() => {
    if (qcReportsId.error) {
      message.error(getMessageInError(qcReportsId.error));
      qcReportsId.clearError();
    }
  }, [qcReportsId.error]);

  return (
    <QcReportUpdateTemplateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        qcReportsId,
        setIsDisabled,
        reportTemplateGetId,
        reportTemplateUpdate,
        setIsRouteToHome,
      }}
    >
      {children}
    </QcReportUpdateTemplateContext.Provider>
  );
};

export default QcReportUpdateTemplateProvider;

export const useContextQcReportUpdateTemplate = (): QcReportUpdateTemplateContext => (
  useContext(QcReportUpdateTemplateContext)
);
