import React from 'react';
import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Content from '../../components/Pages/Records';
import { displayName } from '../../config';
import RecordsProvider from '../../context/recordCreate';

const Records: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Records`;

  return (
    <Layout>
      <PageHeader title="Records" />
      <RecordsProvider>
        <Content />
      </RecordsProvider>
    </Layout>
  );
};

export default Records;
