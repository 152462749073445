import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useNavigate } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError, FetchSuccess } from '../types';
import { FetchCreate, getMessageInError } from '../hooks/fetch';
import { TemplateField, useTemplatesCreate } from '../hooks/templates';

export type ValuesCreateChangeType = { name: string; } | { customers: number[]; } | { commodity: number; }
  | { status: boolean; } | { banner: File | UploadFile | null; } | { templateFields: TemplateField[]; }

export interface TemplatesCreateParams {
  name: string;
  banner: File | UploadFile | null;
  status?: boolean;
  commodity: number;
  customers: number [];
  templateFields: TemplateField[];
}

interface TemplatesCreateContext {
  onSave: () => void;
  values: TemplatesCreateParams;
  setValue: (value: ValuesCreateChangeType) => void;
  isDisabled: boolean;
  templateCreate: FetchCreate<FetchSuccess, FetchError, FormData> | null;
}

const initialValues: TemplatesCreateParams = {
  name: '',
  customers: [],
  commodity: 0,
  banner: null,
  templateFields: [],
};

const defaultValue: TemplatesCreateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  templateCreate: null,
};

export const TemplatesCreateContext = createContext<TemplatesCreateContext>(defaultValue);
interface TemplatesCreateProviderProps {
  children: React.ReactNode;
}
const TemplatesCreateProvider: React.FC<TemplatesCreateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const templateCreate = useTemplatesCreate();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<TemplatesCreateParams>(initialValues);
  const handleValuesChange = (value: ValuesCreateChangeType) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    let isValid = true;

    if (values.templateFields.length > 0) {
      isValid = values.templateFields.every((field) => field.name.trim() !== '')
      && values.templateFields.every((field) => (
        field.type !== 'select' || field.value.every((value) => value.value)
      ));
    }

    isValid = !(isValid && values?.name?.trim() !== '');
    setIsDisabled(isValid);
  }, [values.name, values.templateFields]);

  const onSave = () => {
    if (!isDisabled) {
      const formData = new FormData();

      formData.append('name', values.name);
      formData.append('status', 'true');
      values.templateFields.forEach((x) => {
        const e = x;

        e.name = x.name?.trim();
      });
      values.templateFields.forEach(({ name, value, type, required }, position) => {
        if (name && name.trim() !== '') {
          const fieldData = {
            name,
            type,
            value: type === 'select' || type === 'grading'
              ? value.map((option) => option.value).join(',')
              : {},
            required,
            position,
          };

          formData.append('templateFields[]', JSON.stringify(fieldData));
        }
      });

      if (values.commodity) {
        formData.append('commodity', `${values.commodity}`);
      }

      if (values.customers) {
        values.customers.forEach((customer) => {
          formData.append('customers[]', `${customer}`);
        });
      }

      if (values.banner) {
        formData.append('banner', values.banner as File);
      }

      templateCreate.fetch(formData);
    }
  };

  useEffect(() => {
    if (templateCreate.data && !templateCreate.error) {
      message.success('Created!');
      navigate('/templates', { replace: true });
      setValues(initialValues);
    }
  }, [templateCreate.data]);

  useEffect(() => {
    if (templateCreate.error) {
      message.error(getMessageInError(templateCreate.error));
      templateCreate.clearError();
    }
  }, [templateCreate.error]);

  return (
    <TemplatesCreateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        templateCreate,
      }}
    >
      {children}
    </TemplatesCreateContext.Provider>
  );
};

export default TemplatesCreateProvider;

export const useContextTemplateCreate = (): TemplatesCreateContext => useContext(TemplatesCreateContext);
