import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError, FetchSuccess } from '../types';
import { FetchGetId, FetchUpdate, getMessageInError } from '../hooks/fetch';
import { useWarehouseId, useWarehouseUpdate, WarehouseIdData, WarehouseUpdateParams } from '../hooks/warehouses';

type ValuesChangeType = { name: string; } | { users: number[]; } | { status: boolean; } | { location: string; }

interface WarehousesUpdateContext {
  onSave: () => void;
  values: WarehouseUpdateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  warehouseId: FetchGetId<WarehouseIdData> | null;
  warehouseUpdate: FetchUpdate<FetchSuccess, FetchError, WarehouseUpdateParams> | null;
}

export const initialValues: WarehouseUpdateParams = {
  name: '',
  users: [],
  status: false,
  location: '',
};

const defaultValue = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  warehouseId: null,
  warehouseUpdate: null,
};

export const WarehousesUpdateContext = createContext<WarehousesUpdateContext>(defaultValue);
interface WarehousesUpdateProviderProps {
  children: React.ReactNode;
}
const WarehousesUpdateProvider: React.FC<WarehousesUpdateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const warehouseId = useWarehouseId();
  const warehouseUpdate = useWarehouseUpdate();

  const { id = '' } = useParams<{ id: string; }>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<WarehouseUpdateParams>(initialValues);
  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    setIsDisabled(!values.name);
  }, [values]);

  const onSave = () => {
    if (!isDisabled) {
      warehouseUpdate.fetch(values, id);
    }
  };

  const clear = () => {
    setValues(initialValues);
  };

  useEffect(() => {
    if (warehouseUpdate.data && !warehouseUpdate.error) {
      message.success('Updated!');
      navigate('/warehouses', { replace: true });
      clear();
    }
  }, [warehouseUpdate.data]);

  useEffect(() => {
    if (warehouseUpdate.error) {
      message.error(getMessageInError(warehouseUpdate.error));
      warehouseUpdate.clearError();
    }
  }, [warehouseUpdate.error]);

  useEffect(() => {
    if (+id) {
      warehouseId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (warehouseId.data && !warehouseId.error) {
      setValues({
        name: warehouseId.data.name,
        users: warehouseId.data.users.map((user) => user.id),
        status: warehouseId.data.status,
        location: warehouseId.data.location,
      });
    }
  }, [warehouseId.data]);

  useEffect(() => {
    if (warehouseId.error) {
      getMessageInError(warehouseId.error);
      warehouseId.clearError();
    }
  }, [warehouseId.error]);

  return (
    <WarehousesUpdateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        warehouseId,
        warehouseUpdate,
      }}
    >
      {children}
    </WarehousesUpdateContext.Provider>
  );
};

export default WarehousesUpdateProvider;

export const useContextWarehouseUpdate = (): WarehousesUpdateContext => useContext(WarehousesUpdateContext);
