import React from 'react';
import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Content from '../../components/Pages/Warehouses';
import { displayName } from '../../config';

const Warehouses = ():JSX.Element => {
  document.title = `${displayName}: Warehouses`;
  return (
    <Layout>
      <PageHeader title="Warehouses" />
      <Content />
    </Layout>
  );
};

export default Warehouses;
