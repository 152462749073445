import clsx from 'clsx';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Card, Checkbox, Empty, message, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import { useAuth } from '../../../../context/auth';
import DetailsCard from '../../../Common/DetailsCard';
import TableLoggers from '../../../Common/TableLoggers';
import ModalSendReport from '../../../Common/ModalSendReport';
import { getMessageInError } from '../../../../hooks/fetch';
import { capitalizeFirstLetter } from '../../../../utils';
import { ReportStatus, ReportStatuses } from '../../../../enums/reports';
import { QcReportTemplate, useQcDeleteTemplates, useQcReportsId } from '../../../../hooks/qcReports';

import styles from './index.module.less';
import confirm from '../../../Common/ModalConfirm';
import Loading from '../../../Common/Loading';
import error from '../../../Common/ModalError';

const QcReportProfile: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { id: reportId = '' } = useParams<{ id: string; }>();

  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const [selectedTemplates, setSelectedTemplates] = useState<number[]>([]);

  const [modal, setModal] = useState<boolean>(false);

  const qcReportsId = useQcReportsId();
  const qcDeleteTemplates = useQcDeleteTemplates();

  const handleSelect = (id: number) => {
    const newSelectedTemplates = selectedTemplates.includes(id) ? (
      selectedTemplates.filter((el) => el !== id)
    ) : [...selectedTemplates, id];

    setSelectedTemplates(newSelectedTemplates);
  };

  const handleDelete = () => {
    confirm({
      title: `Delete template${selectedTemplates.length > 1 ? 's' : ''}`,
      content: `Are you sure you want to delete ${selectedTemplates.length > 1 ? 'these' : 'this'}
      template${selectedTemplates.length > 1 ? 's' : ''}, including the photos?`,
      okText: 'Delete',
      onOk: async () => {
        if (selectedTemplates.length) {
          handleAllowDelete();
        }
      },
    });
  };

  const handleAllowDelete = async () => {
    await qcReportsId.fetch(undefined, reportId);

    if (qcReportsId.data?.reportTemplates.some((el) => el.inWork !== null)) {
      return error({
        title: 'Error',
        content: 'You cannot delete template until it has been completed by the another user',
        okText: 'Ok',
      });
    }

    await Promise.all(selectedTemplates.map((id) => qcDeleteTemplates.fetch(id.toString())));
    await qcReportsId.fetch(undefined, reportId);
    setIsDeleteMode(false);
    setSelectedTemplates([]);

    return null;
  };

  const fetchQcReportsId = () => {
    qcReportsId.fetch(undefined, reportId);
  };

  useEffect(() => {
    if (reportId) {
      fetchQcReportsId();
    }
  }, [reportId]);

  useEffect(() => {
    if (qcReportsId.error) {
      message.error(getMessageInError(qcReportsId.error));
      qcReportsId.clearError();
    }
  }, [qcReportsId.error]);

  const routes = [
    {
      path: '/reports/qc/',
      breadcrumbName: 'QC Reports',
    },
    {
      breadcrumbName: qcReportsId?.data?.record.bolAwb
        ? `${qcReportsId?.data?.record.type.toUpperCase()} ${qcReportsId.data.record.bolAwb}` : 'Report details',
    },
  ];

  const getLpReportTagClass = (status: ReportStatus): string => {
    switch (status) {
      case 'in progress':
        return 'status-in-progress';
      case 'completed':
        return 'status-completed';
      case 'expired':
        return 'status-expired';
      case 'not started':
        return 'status-not-started';
      default:
        return '';
    }
  };

  const getHeaderExtra = (status: string): JSX.Element => {
    switch (status) {
      case 'not started':
        return (
          <Button ghost onClick={() => navigate(`/reports/qc/create/${reportId}`, { replace: false })}>
            Add New Template
          </Button>
        );
      case 'in progress':
        return (
          <>
            {
              qcReportsId?.data?.reportTemplates && qcReportsId?.data?.reportTemplates.length > 1 ? (
                <Button
                  ghost
                  className={selectedTemplates.length ? styles.deleteBtn : ''}
                  onClick={() => {
                    setIsDeleteMode(!selectedTemplates.length ? !isDeleteMode : true);

                    if (selectedTemplates.length) handleDelete();
                  }}
                >
                  {`Remove ${isDeleteMode && selectedTemplates.length ? ' selected' : ' templates'}`}
                </Button>
              ) : null
            }
            <Button type="primary" onClick={() => navigate(`/reports/qc/generate/${reportId}`)}>
              Review
            </Button>
          </>
        );
      case 'completed':
        return (
          <>
            <Button
              type="primary"
              href={`${qcReportsId.data?.pdfUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              Download PDF
            </Button>
            <Button type="primary" onClick={() => setModal(true)}>Send</Button>
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {qcReportsId.loading || qcDeleteTemplates.loading ? <Loading visible size="default" absolute /> : null}
      <PageHeader
        title={qcReportsId.data?.record.bolAwb ? (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '16px' }}>
              {`${qcReportsId.data.record.type.toUpperCase()} ${qcReportsId.data.record.bolAwb}`}
            </div>
            {qcReportsId.data.status ? (
              <div>
                <Tag
                  className={getLpReportTagClass(qcReportsId?.data.status)}
                  style={getLpReportTagClass(qcReportsId?.data.status) !== ''
                    ? { borderRadius: '35px', background: 'transparent' }
                    : { color: '#FAAD14', borderColor: '#FAAD14', borderRadius: '35px', background: 'transparent' }}
                >
                  {capitalizeFirstLetter(ReportStatuses[qcReportsId?.data.status])}
                </Tag>
              </div>
            ) : null}
          </div>
        ) : 'Report details'}
        extra={getHeaderExtra(qcReportsId?.data?.status || '')}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <ModalSendReport isOpen={modal} close={() => setModal(false)} reportId={+reportId} category="qc" />
        {qcReportsId.data?.reportTemplates.length ? (
          <div className={clsx(styles.cardWrapper, 'template-card')}>
            {qcReportsId.data?.reportTemplates.map((template: QcReportTemplate) => (
              <div key={template.id} style={{ position: 'relative' }}>
                {
                  isDeleteMode ? (
                    <Checkbox
                      disabled={
                      !selectedTemplates.includes(template.id)
                        && qcReportsId.data?.reportTemplates
                        && selectedTemplates.length >= qcReportsId.data?.reportTemplates.length - 1
                      }
                      onClick={() => handleSelect(template.id)}
                      className={styles.selectedCheckbox}
                    />
                  ) : null
                }
                <DetailsCard
                  firstGeneratedAt={qcReportsId.data?.firstGeneratedAt || ''}
                  reportId={reportId}
                  isQcReport
                  key={template.id}
                  inWork={template.inWork && template.inWork.id !== user.id ? template.inWork : null}
                  onEdit={() => navigate(`/reports/qc/update/${reportId}/template/${template.id}`, { replace: false })}
                  onPreview={() => navigate(`/reports/qc/${reportId}/template/${template.id}`, { replace: false })}
                  itemPhoto={template.template.banner?.id}
                  bannerUrl={template.template.banner?.url}
                  imagesCount={template.prevImages?.length || template.images.length}
                  itemTypeName={template.template.name || 'Type name'}
                  reportStatus={qcReportsId.data?.status || 'not started'}
                />
              </div>
            ))}
          </div>
        ) : (
          <Card style={{ marginBottom: 24 }} bodyStyle={{ padding: 16 }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Card>
        )}
        <TableLoggers entity="reports" />
      </Content>
    </>
  );
};

export default QcReportProfile;
