import React from 'react';
import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Content from '../../components/Pages/Commodities';
import { displayName } from '../../config';
import CommoditiesProvider from '../../context/commodities';

const Commodities: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Commodities`;

  return (
    <Layout>
      <PageHeader title="Commodities" />
      <CommoditiesProvider>
        <Content />
      </CommoditiesProvider>
    </Layout>
  );
};

export default Commodities;
