import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DatePicker, Form, Input, message, Modal, Select } from 'antd';
import moment from 'moment-timezone';
import { required } from '../../../../utils/inputRules';
import SelectCustomer from '../../../Common/SelectCustomer';
import SelectWarehouse from '../../../Common/SelectWarehouse';
import SelectCommodityMulti from '../../../Common/SelectCommodityMulti';
import { getMessageInError } from '../../../../hooks/fetch';
import { RecordCreateParams } from '../../../../hooks/records';
import { useContextRecordCreate } from '../../../../context/recordCreate';
import NotFoundContent from '../../../Common/NotFoundContent';
import SelectAwbRecordMulti from '../../../Common/SelectAwbRecordMulti';
import { useAuth } from '../../../../context/auth';

interface ModalCreateRecord {
  isOpen: boolean;
  close: () => void;
}

const initialValues: RecordCreateParams = {
  warehouse: 0,
  type: '',
  bolAwb: '',
  po: '',
  log_number: '',
  cut_off_date: '',
  customer: 0,
  commodities: [],
  loading_notes: '',
  awb_record_ids: [],
};

const ModalCreateRecord: React.FC<ModalCreateRecord> = ({ isOpen, close }): JSX.Element => {
  const { recordCreate } = useContextRecordCreate();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [values, setValues] = useState<RecordCreateParams>(initialValues);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (user.role === 'user' as string) {
      setValues({ ...values, type: 'misc' });
      form.setFieldsValue({ type: 'misc' });
    }
  }, [user]);

  const handleOk = () => {
    form.validateFields().then(() => {
      recordCreate?.fetch({
        ...values,
        po: values.po || undefined,
        log_number: values.type === 'awb' ? values.log_number : undefined,
        cut_off_date: values.type === 'awb'
          ? `${moment(values.cut_off_date).format('YYYY-MM-DDTHH:mm:00')}Z`
          : undefined,
        customer: values.customer || 0,
        commodities: values.type === 'bol' ? values.commodities : undefined,
        loading_notes: values.type === 'awb' ? values.loading_notes : undefined,
        awb_record_ids: values.type === 'bol' ? values.awb_record_ids : undefined,
        userGroup: values.type === 'bol' ? values.userGroup : undefined,
      });
    });
  };

  useEffect(() => {
    const isValid = !!(values.warehouse && values.type && values.bolAwb && (
      values.type === 'awb' ? values.cut_off_date : true)
    );

    setIsDisabled(!isValid);
  }, [values]);

  useEffect(() => {
    if (recordCreate?.data && !recordCreate.error) {
      message.success('Created!');
      navigate(`/records/update/${recordCreate.data.id}`, { replace: true });
      close();
    }
  }, [recordCreate?.data]);

  useEffect(() => {
    if (recordCreate?.error) {
      message.error(getMessageInError(recordCreate.error));
      recordCreate.clearError();
    }
  }, [recordCreate?.error]);

  const getBolAwbName = () => {
    if (values.type === 'awb') return 'AWB#';
    if (values.type === 'bol') return 'BOL#';
    if (values.type === 'misc') return 'Title';

    return 'Record#';
  };

  return (
    <>
      <Modal
        onOk={handleOk}
        title="Add New Record"
        okText="Save"
        open={isOpen}
        onCancel={close}
        afterClose={form.resetFields}
        okButtonProps={{ disabled: isDisabled }}
        confirmLoading={recordCreate?.loading}
        destroyOnClose
        width={570}
      >
        <Form
          form={form}
          name="record_create"
          layout="horizontal"
          labelCol={{ span: values.type === 'bol' ? 7 : 5 }}
          autoComplete="false"
          onValuesChange={(value) => setValues({ ...values, ...value })}
        >
          <Form.Item name="warehouse" label="Warehouse" rules={required}>
            <SelectWarehouse activeOnly />
          </Form.Item>
          <Form.Item
            name="type"
            label="Record type"
            rules={required}
          >
            <Select
              options={[
                { value: 'awb', label: 'AWB' },
                { value: 'bol', label: 'BOL' },
                { value: 'misc', label: 'MISC' },
              ]}
              allowClear
              showSearch
              disabled={user.role === 'user' as string}
              placeholder="Please select"
              notFoundContent={<NotFoundContent message="Report type not found." />}
            />
          </Form.Item>
          <Form.Item
            name="bolAwb"
            label={getBolAwbName()}
            rules={required}
          >
            <Input type="text" placeholder="Please enter" />
          </Form.Item>
          {values.type === 'bol' && (
            <Form.Item name="awb_record_ids" label="Parent AWB records">
              <SelectAwbRecordMulti />
            </Form.Item>
          )}
          {values.type === 'bol' && (
            <Form.Item name="userGroup" label="User group">
              <Input type="text" placeholder="Please enter" />
            </Form.Item>
          )}
          {values.type !== 'misc' ? (
            <Form.Item name="po" label="PO#">
              <Input type="text" placeholder="Please enter" />
            </Form.Item>
          ) : null}
          {values.type === 'awb' && (
            <Form.Item name="log_number" label="Log#">
              <Input type="text" placeholder="Please enter" />
            </Form.Item>
          )}
          {values.type === 'awb' && (
            <Form.Item name="cut_off_date" label="Cut off date" rules={required}>
              <DatePicker showTime={{ format: 'HH:mm' }} style={{ width: '100%' }} format="MM-DD-YYYY HH:mm" />
            </Form.Item>
          )}
          {values.type !== 'misc' ? (
            <Form.Item name="customer" label="Customer">
              <SelectCustomer activeOnly />
            </Form.Item>
          ) : null}
          {values.type === 'bol' && (
            <Form.Item name="commodities" label="Commodity">
              <SelectCommodityMulti activeOnly />
            </Form.Item>
          )}
          {values.type === 'awb' && (
            <Form.Item name="loading_notes" label="Loading notes">
              <Input type="text" placeholder="Please enter" />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateRecord;
