import React from 'react';
import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Content from '../../components/Pages/CompletedReports';
import { displayName } from '../../config';

const CompletedReports: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Completed Reports`;

  return (
    <Layout>
      <PageHeader title="Completed Reports" />
      <Content />
    </Layout>
  );
};

export default CompletedReports;
