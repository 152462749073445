import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { EyeOutlined, SwapOutlined } from '@ant-design/icons/lib';
import { EditOutlined, FieldTimeOutlined } from '@ant-design/icons';

import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import error from '../ModalError';
import { icons } from '../../Pages/LpReportTypes/Icons/data';
import { ReportStatus } from '../../../enums/reports';
import { QcReportTemplateInWork } from '../../../hooks/qcReports';

import styles from './index.module.less';

interface DetailsCard {
  reportId?: string;
  onEdit: () => void;
  inWork: QcReportTemplateInWork | null;
  itemIcon?: string;
  onPreview: () => void;
  itemPhoto?: number;
  imagesCount: number;
  itemTypeName: string;
  reportStatus: ReportStatus;
  firstGeneratedAt?: string;
  isQcReport?: boolean;
  bannerUrl?: string;
}

const DetailsCard: React.FC<DetailsCard> = ({
  reportId,
  onEdit,
  inWork,
  itemIcon,
  onPreview,
  itemPhoto,
  imagesCount,
  itemTypeName,
  reportStatus,
  isQcReport,
  bannerUrl,
}) => {
  const iconsArray = Object.entries(icons);
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <div className={styles.photoWrapper}>
        {itemIcon || bannerUrl ? (
          <>
            {itemIcon && (
              <div className={styles.icon}>
                {iconsArray.filter(([key]) => itemIcon === key).map(([key, Icon]) => (
                  <Icon key={key} />
                ))}
              </div>
            )}
            {bannerUrl && (
              <div
                style={{ backgroundImage: `url(${bannerUrl})` }}
                className={styles.image}
              />
            )}
          </>
        ) : (
          <div className={styles.icon}>
            {(() => {
              const [name, Icon] = iconsArray[34];

              return <Icon key={name} />;
            })()}
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.cardBody}>
          {itemTypeName || 'Type'}
        </div>
        <div className={styles.cardFeatures}>
          {`Photos: ${imagesCount || '0'}`}
        </div>
      </div>

      <div className={clsx(
        styles.actionWrapper,
        reportStatus === 'completed' ? styles.completed : '',
      )}
      >
        {reportStatus === 'completed' && (
          <Button
            className={styles.btnEdit}
            onClick={() => navigate(`/reports/${isQcReport ? 'qc' : 'lp'}/regenerate/${reportId}`)}
          >
            <EditOutlined />
          </Button>
        )}
        <Button
          className={styles.btnEdit}
          onClick={(() => {
            if (reportStatus === 'completed' || reportStatus === 'expired') {
              return onPreview;
            }

            return inWork ? () => error({
              title: 'The template is currently being edited',
              content: `You cannot start working on a template until it has been completed by the current user ${
                `${inWork?.firstName} ${inWork?.lastName}`}.`,
            }) : onEdit;
          })()}
        >
          {reportStatus === 'completed' ? (
            <EyeOutlined />
          ) : (
            <>
              {inWork ? <FieldTimeOutlined /> : <EditOutlined />}
            </>
          )}
        </Button>
        {reportStatus === 'completed' && (
          <Button
            className={styles.btnEdit}
            onClick={() => navigate(`/reports/${isQcReport ? 'qc' : 'lp'}/reassign/${reportId}`)}
          >
            <SwapOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};

DetailsCard.defaultProps = {
  itemIcon: '',
  itemPhoto: undefined,
  firstGeneratedAt: undefined,
  reportId: undefined,
  isQcReport: true,
  bannerUrl: '',
};

export default DetailsCard;
