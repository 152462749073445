import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons/lib';

import { useAuth } from '../../../../context/auth';
import { gradingColors } from '../../../Common/TemplateForm';
import { TemplateField } from '../../../../hooks/templates';
import { required as ruleRequired } from '../../../../utils/inputRules';
import SelectQualityReportFieldType from '../../../Common/SelectQualityReportFieldType';

import styles from './index.module.less';

interface QualityReportFields {
  onSave: () => void;
  loading?: boolean;
  templateFields: TemplateField[];
  setTemplateFields: (templateFields: TemplateField[]) => void;
}

const QualityReportFields: React.FC<QualityReportFields> = ({
  onSave,
  loading,
  templateFields,
  setTemplateFields,
}) => {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const { isRoleEnough } = useAuth();

  const [sort, setSort] = useState<boolean>(false);
  const isDisabledRule = pathname.includes('generic') ? !isRoleEnough('admin') : !isRoleEnough('manager');

  const onChange = (templateFieldValue: TemplateField, id: number) => {
    setTemplateFields(templateFields.map((templateField) => {
      if (id === templateField.id) {
        return templateFieldValue;
      }

      return templateField;
    }));
  };

  const onOptionChange = (option: { id: number; value: string; }, rowId: number) => {
    setTemplateFields(templateFields.map((templateField): TemplateField => {
      if (rowId === templateField.id) {
        return {
          ...templateField,
          value: templateField.value.map((templateFieldOption) => {
            if (templateFieldOption.id === option.id) {
              return option;
            }

            return templateFieldOption;
          }),
        };
      }

      return templateField;
    }));
  };

  const addTemplateField = () => {
    setTemplateFields([
      ...templateFields,
      {
        id: Date.now(),
        name: '',
        type: 'text',
        value: [],
        required: true,
        position: templateFields.length,
      },
    ]);
  };

  const removeTemplateField = (rowId: number): void => {
    setTemplateFields(templateFields.filter((field) => field.id !== rowId));
  };

  const addOptionField = (rowId: number) => {
    setTemplateFields(templateFields.map((templateField): TemplateField => {
      if (templateField.id === rowId) {
        return {
          ...templateField,
          value: [...templateField.value, { id: Date.now(), value: '' }],
        };
      }

      return templateField;
    }));
  };

  const removeOptionField = (rowId: number, optionId: number) => {
    setTemplateFields(templateFields.map((templateField): TemplateField => {
      if (templateField.id === rowId) {
        return {
          ...templateField,
          value: templateField.value.filter((value) => value.id !== optionId),
        };
      }

      return templateField;
    }));
  };

  useEffect(() => {
    if (form && templateFields.length) {
      const newValues: Record<string, string | boolean> = {};

      templateFields.forEach(({ id, name, required, type, value }) => {
        newValues[`${id}_name`] = name;
        newValues[`${id}_type`] = type;
        newValues[`${id}_required`] = required;

        value.forEach((option) => {
          newValues[`${id}_${option.id}`] = option.value;
        });
      });

      form.setFieldsValue(newValues);
    }
  }, [form, templateFields]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number; }) => {
    if (oldIndex !== newIndex) {
      const current: TemplateField = templateFields[oldIndex];

      const filtered = templateFields.filter((_, i) => i !== oldIndex);

      if (newIndex === filtered.length) {
        setTemplateFields([...filtered, current]);
      } else {
        setTemplateFields(filtered.reduce((acc: TemplateField[], id, index) => (
          index === newIndex ? [...acc, current, id] : [...acc, id]
        ), []));
      }
    }
  };

  const SortableItem: any = SortableElement(({ row }: { row: TemplateField; }) => (
    <div key={row.id} className={styles.templateRowBorder}>
      <Row gutter={24} className={styles.templateRowPadding}>
        <Col style={{ display: 'flex', alignItems: 'center', width: 38 }}>{sort && <MenuOutlined />}</Col>
        <Col span={6}>
          <Form.Item name={`${row.id}_name`} style={{ margin: 0 }} rules={ruleRequired}>
            <Input
              type="text"
              disabled={isDisabledRule || sort}
              onChange={({ target: { value } }) => onChange({ ...row, name: value }, row.id)}
              placeholder="Please enter"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={`${row.id}_type`} style={{ margin: 0 }} rules={ruleRequired}>
            <SelectQualityReportFieldType
              disabled={isDisabledRule || sort}
              onChange={(type) => onChange({
                ...row,
                type,
                value: (type === 'grading' && [{ id: 0, value: 'High' }, { id: 1, value: 'Moderate' },
                  { id: 2, value: 'Low' }, { id: 3, value: 'None' }]) || [{ id: 0, value: '' }],
              }, row.id)}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item style={{ margin: 0 }} name={`${row.id}_required`}>
            <Input
              type="checkbox"
              checked={row.required}
              disabled={isDisabledRule || sort}
              onChange={({ target: { checked } }) => onChange({ ...row, required: checked }, row.id)}
            />
          </Form.Item>
        </Col>
        {!sort && !isDisabledRule ? (
          <Col span={3}>
            <Button type="primary" onClick={addTemplateField} icon={<PlusOutlined />} />
            <Button
              icon={<DeleteOutlined />}
              style={{ marginLeft: 16 }}
              onClick={() => removeTemplateField(row.id)}
            />
          </Col>
        ) : null}
      </Row>
      {row.type === 'select' && row.value.map((value, optionIndex) => (
        <Row key={value.id} gutter={24} className={styles.templateRowPadding}>
          <Col span={6} style={{ marginLeft: 38 }}>
            <Form.Item style={{ margin: 0 }} name={`${row.id}_${value.id}`} rules={ruleRequired}>
              <Input
                type="text"
                value={value.value}
                disabled={isDisabledRule || sort}
                onChange={({ target }) => onOptionChange({ id: value.id, value: target.value }, row.id)}
              />
            </Form.Item>
          </Col>
          {!sort && !isDisabledRule && (
            <Col span={3}>
              {row.value.length && optionIndex !== row.value.length - 1 ? (
                <Button onClick={() => removeOptionField(row.id, value.id)} icon={<DeleteOutlined />} />
              ) : (
                <Button type="primary" onClick={() => addOptionField(row.id)} icon={<PlusOutlined />} />
              )}
            </Col>
          )}
        </Row>
      ))}
      {row.type === 'grading' && row.value.map((value, i) => (
        <Row key={value.id} gutter={24} className={styles.templateRowPadding}>
          <Col span={6} style={{ marginLeft: 38 }}>
            <Form.Item style={{ margin: 0 }} name={`${row.id}_${value.id}`} rules={ruleRequired}>
              <Input
                type="text"
                value={value.value}
                prefix={<div className={styles.gradingPoint} style={{ backgroundColor: gradingColors[i] }} />}
                disabled={isDisabledRule || sort}
                onChange={({ target }) => onOptionChange({ id: value.id, value: target.value }, row.id)}
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </div>
  ));

  const SortableList = SortableContainer(() => (
    <ul>
      {templateFields.map((row, index) => (
        <SortableItem key={`item-${row.id}`} index={index} row={row} />
      ))}
    </ul>
  ));

  return (
    <Card
      className="template-reports"
      title="Quality Report Fields"
      extra={!isDisabledRule && (
        <>
          <Button type="primary" onClick={addTemplateField} icon={<PlusOutlined />} />
          <Button type="default" onClick={() => setSort(!sort)}>
            Sort
          </Button>
        </>
      )}
      loading={loading}
    >
      {(templateFields.length > 0) && (
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        className="template_create"
      >
        <Row gutter={24} className={styles.headRow}>
          <Col span={6} style={{ marginLeft: 38 }}>
            Name
          </Col>
          <Col span={4}>
            Type
          </Col>
          <Col span={2}>
            Required
          </Col>
        </Row>
        {sort ? (
          <SortableList onSortEnd={onSortEnd} />
        ) : (
          templateFields.map((row) => (
            <div key={row.id} className={styles.templateRowBorder}>
              <Row gutter={24} className={styles.templateRowPadding}>
                <Col style={{ display: 'flex', alignItems: 'center', width: 38 }}>{sort && <MenuOutlined />}</Col>
                <Col span={6}>
                  <Form.Item name={`${row.id}_name`} style={{ margin: 0 }} rules={ruleRequired}>
                    <Input
                      type="text"
                      disabled={isDisabledRule || sort}
                      onChange={({ target: { value } }) => onChange({ ...row, name: value }, row.id)}
                      placeholder="Please enter"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={`${row.id}_type`} style={{ margin: 0 }} rules={ruleRequired}>
                    <SelectQualityReportFieldType
                      disabled={isDisabledRule || sort}
                      onChange={(type) => onChange({
                        ...row,
                        type,
                        value: (type === 'grading' && [{ id: 0, value: 'High' }, { id: 1, value: 'Moderate' },
                          { id: 2, value: 'Low' }, { id: 3, value: 'None' }]) || [{ id: 0, value: '' }],
                      }, row.id)}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item style={{ margin: 0 }} name={`${row.id}_required`}>
                    <Input
                      type="checkbox"
                      checked={row.required}
                      disabled={isDisabledRule || sort}
                      onChange={({ target: { checked } }) => onChange({ ...row, required: checked }, row.id)}
                    />
                  </Form.Item>
                </Col>
                {!sort && !isDisabledRule ? (
                  <Col span={3}>
                    {templateFields.length > 0 && (
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ marginRight: 16 }}
                      onClick={() => removeTemplateField(row.id)}
                    />
                    )}
                  </Col>
                ) : null}
              </Row>
              {row.type === 'select' && row.value.map((value, optionIndex) => (
                <Row key={value.id} gutter={24} className={styles.templateRowPadding}>
                  <Col span={6} style={{ marginLeft: 38 }}>
                    <Form.Item style={{ margin: 0 }} name={`${row.id}_${value.id}`} rules={ruleRequired}>
                      <Input
                        type="text"
                        value={value.value}
                        disabled={isDisabledRule || sort}
                        onChange={({ target }) => onOptionChange({ id: value.id, value: target.value }, row.id)}
                      />
                    </Form.Item>
                  </Col>
                  {!sort && !isDisabledRule && (
                  <Col span={3}>
                    {row.value.length > 1 && (
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ marginRight: 16 }}
                      onClick={() => removeOptionField(row.id, value.id)}
                    />
                    )}
                    {optionIndex === row.value.length - 1 && (
                    <Button type="primary" onClick={() => addOptionField(row.id)} icon={<PlusOutlined />} />
                    )}
                  </Col>
                  )}
                </Row>
              ))}
              {row.type === 'grading' && row.value.map((value, index) => (
                <Row key={value.id} gutter={24} className={styles.templateRowPadding}>
                  <Col span={6} style={{ marginLeft: 38 }}>
                    <Form.Item style={{ margin: 0 }} name={`${row.id}_${value.id}`} rules={ruleRequired}>
                      <Input
                        type="text"
                        value={value.value}
                        prefix={(
                          <div className={styles.gradingPoint} style={{ backgroundColor: gradingColors[index] }} />
                        )}
                        disabled={isDisabledRule || sort}
                        onChange={({ target }) => onOptionChange({ id: value.id, value: target.value }, row.id)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </div>
          ))
        )}
      </Form>
      )}
    </Card>
  );
};

QualityReportFields.defaultProps = {
  loading: false,
};

export default QualityReportFields;
