import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import { Button, Card, Checkbox, Modal, Space, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { PlusOutlined } from '@ant-design/icons/lib';
import clsx from 'clsx';
import { useGalleryGet, useUploadImagesToGallery } from '../../../../hooks/gallery';
import Loading from '../../../Common/Loading';
import { ReportImages } from '../../../../context/qcReportUpdateTemplate';

import './index.less';

interface GalleryModalProps {
  visible: boolean;
  title: string;
  handleVisibility: () => void;
  setValue: (value: {galleryImages: ReportImages[];}) => void;
  checkedImages: ReportImages[];
}

interface GalleryData {
  images: ReportImages[];
  currentPage: number;
  totalPages: number;
}

const GalleryModal: FC<GalleryModalProps> = ({
  visible,
  title,
  handleVisibility,
  setValue,
  checkedImages,
}) => {
  const uploadNewImages = useUploadImagesToGallery();
  const galleryGet = useGalleryGet();
  const [galleryData, setGalleryData] = useState<GalleryData>({
    images: [],
    currentPage: 1,
    totalPages: 1,
  });
  const [selectedImages, setSelectedImages] = useState<ReportImages[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastImageRef = useCallback((node: any) => {
    if (galleryGet.loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        handleLoadMore();
      }
    });

    if (node) observer.current.observe(node);
  }, [galleryData.images]);

  const handleLoadMore = () => {
    if (galleryData.currentPage >= galleryData.totalPages) {
      return;
    }

    galleryGet.fetch({ limit: 12, page: galleryData.currentPage + 1 }).then((res) => {
      if (res) {
        setGalleryData({
          ...galleryData,
          images: [...galleryData.images, ...res.items],
          currentPage: res.meta.currentPage,
        });
      }
    });
  };

  // fetch initial data
  useEffect(() => {
    if (visible) {
      setSelectedImages(checkedImages);

      if (!galleryData.images.length) {
        galleryGet.fetch({ limit: 12, page: galleryData.currentPage }).then((res) => {
          if (res) {
            setGalleryData({
              images: res.items,
              currentPage: res.meta.currentPage,
              totalPages: res.meta.totalPages,
            });
          }
        });
      }
    }

    return () => {
      setSelectedImages(checkedImages);
    };
  }, [visible]);

  const handleSelectImage = (image: ReportImages) => {
    if (!selectedImages.some(({ id }) => id === +image.id)) {
      setSelectedImages([...selectedImages, image]);

      return;
    }

    setSelectedImages(selectedImages.filter(({ id }) => id !== +image.id));
  };

  const handleSelectAll = () => {
    setIsSelectedAll(!isSelectedAll);
    setSelectedImages(isSelectedAll ? [] : galleryData.images);
  };

  const handleUpload = () => {
    setValue({ galleryImages: selectedImages });
    handleCloseModal();
  };

  const handleUploadNewImages = async ({ file: { status, originFileObj } }: UploadChangeParam) => {
    const formData = new FormData();

    if (status === 'done') {
      formData.append('image', originFileObj as File);

      await uploadNewImages.fetch(formData)
        .then(() => setGalleryData({ images: [], currentPage: 1, totalPages: 1 }));
      await galleryGet.fetch({ limit: 12, page: 1 })
        .then((res) => {
          if (res) {
            setGalleryData({
              images: res.items,
              currentPage: res.meta.currentPage,
              totalPages: res.meta.totalPages,
            });
          }
        });
    }
  };

  useEffect(() => {
    setSelectedImages(checkedImages);
  }, [checkedImages]);

  const customRequest = ({ onSuccess }: UploadRequestOption): void => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess('ok');
      }
    }, 0);
  };

  const handleCloseModal = () => {
    handleVisibility();
    setIsSelectedAll(false);
  };

  return (
    <Modal
      className="galleryModal"
      open={visible}
      width={1180}
      footer={null}
      onCancel={handleCloseModal}
    >
      <Card
        className="card"
        title={title}
        extra={(
          <Space size={8}>
            <Button type="default" onClick={handleSelectAll}>{isSelectedAll ? 'Deselect all' : 'Select all'}</Button>
            <Button type="primary" style={{ marginRight: 20 }} onClick={handleUpload}>Attach photos</Button>
          </Space>
        )}
      >
        <div className="imagesList">
          {
            galleryData.images?.map((imageData, index) => (
              <div
                ref={galleryData.images.length === index + 1 ? lastImageRef : null}
                key={imageData.id}
                className={clsx(
                  'imageBlock',
                  checkedImages?.some(({ id, originalName }) => (
                    id === +imageData.id || originalName === imageData.originalName)) ? 'checked' : '',
                )}
                onClick={() => handleSelectImage(imageData)}
              >
                <img
                  src={`${imageData.url}`}
                  alt={imageData.fileName}
                />
                <div className="date">{imageData.timeStampString}</div>
                <Checkbox
                  className="checkbox"
                  checked={checkedImages?.some(({ id, originalName }) => (
                    id === +imageData.id || originalName === imageData.originalName))
                    || selectedImages.some(({ id }) => id === +imageData.id)}
                />
              </div>
            ))
            }
          <Upload
            multiple
            listType="picture-card"
            onChange={handleUploadNewImages}
            showUploadList={false}
            customRequest={customRequest}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </div>
        {galleryGet.loading || uploadNewImages.loading ? <Loading visible size="default" absolute /> : null}
      </Card>
    </Modal>
  );
};

export default GalleryModal;
