import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError } from '../types';
import { FetchCreate, getMessageInError } from '../hooks/fetch';
import { LpReportCreateParams, ReportTemplateField, ResponseLpReport, useLpReportCreate } from '../hooks/lpReports';
import { ReportImages } from './qcReportUpdateTemplate';

type ValuesChangeType = { record: number; } | { type: number; } | { images: UploadFile[]; }
  | { template: number; } | { reportTemplateFields: ReportTemplateField[]; }
  | { galleryImages: ReportImages[]; };

interface LpReportsCreateContext {
  onSave: () => void;
  values: LpReportCreateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  lpReportCreate: FetchCreate<ResponseLpReport, FetchError, FormData> | null;
  setIsDisabled: (disabled: boolean) => void;
}

export const initialValues: LpReportCreateParams = {
  record: 0,
  images: [],
  type: 0,
  galleryImages: [],
};

const defaultValue: LpReportsCreateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  setIsDisabled: () => undefined,
  isDisabled: false,
  lpReportCreate: null,
};

export const LpReportsCreateContext = createContext<LpReportsCreateContext>(defaultValue);
interface LpReportsCreateProviderProps {
  children: React.ReactNode;
}
const LpReportsCreateProvider: React.FC<LpReportsCreateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const lpReportCreate = useLpReportCreate();
  const { id: recordId = '' } = useParams<{ id: string; }>();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<LpReportCreateParams>({ ...initialValues, record: recordId ? +recordId : 0 });
  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    const isValid = !!(values.record && values.type && values.images.length);

    setIsDisabled(!isValid);
  }, [values.record, values.type, values.images]);

  const onSave = () => {
    if (values.record && values.type && values.images.length) {
      const formData = new FormData();

      formData.append('record', `${values.record}`);
      formData.append('type', `${values.type}`);
      values.images.map(({ originFileObj }) => originFileObj).forEach((image) => {
        if (image) {
          formData.append('images', image as File);
        }
      });

      if (values.galleryImages.length) {
        formData.append('galleryImages', JSON.stringify(values.galleryImages.map((el) => el.id)));
      }

      lpReportCreate.fetch(formData);
    }
  };

  useEffect(() => {
    if (lpReportCreate.data && !lpReportCreate.error) {
      message.success('Created!');
      setValues({ ...initialValues, record: recordId ? +recordId : 0 });
      navigate(`/reports/lp/${lpReportCreate.data.id}`, { replace: true });
    }
  }, [lpReportCreate.data]);

  useEffect(() => {
    if (lpReportCreate.error) {
      message.error(getMessageInError(lpReportCreate.error));
      lpReportCreate.clearError();
    }
  }, [lpReportCreate.error]);

  return (
    <LpReportsCreateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        setIsDisabled,
        lpReportCreate,
      }}
    >
      {children}
    </LpReportsCreateContext.Provider>
  );
};

export default LpReportsCreateProvider;

export const useContextLpReportCreate = (): LpReportsCreateContext => useContext(LpReportsCreateContext);
