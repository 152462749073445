import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload/interface';
import { useParams } from 'react-router-dom';
import { ReportImages } from '../../../../context/qcReportUpdateTemplate';
import { useReportTemplateUpdate } from '../../../../hooks/reportTemplate';
import Loading from '../../Loading';
import { useLpReportsId } from '../../../../hooks/lpReports';
import { QcReportTemplate, useQcReportsId } from '../../../../hooks/qcReports';

import styles from '../../../Pages/LpReports/Regenerate/LpReportRegenerateStep/index.module.less';

interface IImagesList {
  templateId: number;
  selected: ReportImages[];
  handleChecked: (checked: boolean, images: ReportImages[]) => void;
  timestamp: boolean;
  isQcReport: boolean;
  values: QcReportTemplate;
  setValues: (value: QcReportTemplate) => void;
  currentStep: number;
}

let prevImages: ReportImages[] = [];

const ImagesList: FC<IImagesList> = ({
  templateId,
  selected,
  handleChecked,
  timestamp,
  isQcReport,
  setValues,
  values,
  currentStep,
}) => {
  const { id = '' } = useParams<{ id: string; }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reportTemplateUpdate = useReportTemplateUpdate();
  const lpReportsId = useLpReportsId();
  const qcReportsId = useQcReportsId();

  useEffect(() => {
    prevImages = values.images;
  }, []);

  useEffect(() => {
    if (!prevImages.length) return;

    const newImages = values.images.filter((item2) => !prevImages
      .some((item1) => item1.id === item2.id));

    if (!newImages.length) return;

    handleChecked(true, newImages);
  }, [values.images]);

  const customRequest = async ({ onSuccess, file }: UploadRequestOption) => {
    try {
      if (onSuccess) {
        const formData = new FormData();

        formData.append('images', file);
        setIsLoading(true);

        await reportTemplateUpdate.fetch(formData, templateId);

        const res = isQcReport ? (
          await qcReportsId.fetch(undefined, id)
        ) : await lpReportsId.fetch(undefined, id);

        if (res?.reportTemplates) {
          setValues({ ...values, images: res.reportTemplates[currentStep].images });
        }

        setIsLoading(false);
        onSuccess('ok');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBeforeUpload = (image: RcFile) => {
    const isValidType = image.type === 'image/jpeg' || image.type === 'image/png';
    const isValidSize = image.size / 1024 / 1024 <= 10;

    return isValidType && isValidSize;
  };

  return (
    <div className={styles.wrapper}>
      {values?.images.map((image) => (
        <div key={image.id} className={styles.imageWrapper}>
          <div
            style={{ backgroundImage: `url(${image.url})` }}
            className={styles.img}
          />
          <div className={styles.date} style={{ display: timestamp ? 'block' : 'none' }}>
            {image.timeStampString}
          </div>
          <Checkbox
            checked={selected.some(({ id: imageId }) => imageId === image.id)}
            onChange={({ target: { checked } }) => handleChecked(checked, [image])}
            className={styles.checkbox}
          />
        </div>
      ))}
      <Upload
        multiple
        className="galleryUploader"
        accept="image/png, image/jpeg"
        listType="picture-card"
        showUploadList={false}
        customRequest={customRequest}
        beforeUpload={handleBeforeUpload}
      >
        <div>
          <Loading visible={isLoading} absolute size="default" />
          {!isLoading ? (
            <>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </>
          ) : null}
        </div>
      </Upload>
    </div>
  );
};

export default ImagesList;
