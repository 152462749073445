import { UploadFile } from 'antd/es/upload/interface';

import { CustomerSimple } from './customers';
import { CommoditySimple } from './commodities';
import { TemplateFieldTypes } from '../enums/templates';
import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

interface TemplateSimple {
  id: number;
  name: string;
  status: boolean;
}

export interface Template extends TemplateSimple {
  banner: File | UploadFile | null;
  created: string;
  updated: string;
  template: {
    id: number;
    name: string;
    banner: { id: number; created: string; url: string; };
  };
  customers: CustomerSimple[];
  commodity: CommoditySimple;
  templateFields: TemplateField[];
}

export interface TemplatesGetParams {
  id?: number;
  name?: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: boolean;
  orderBy?: 'ASC' | 'DESC';
  customer?: number;
  commodity?: number;
  orderByColumn?: 'id' | 'name' | 'status' | 'commodity' | 'customers' | 'created' | 'updated';
}

export interface TableTemplateRow extends TemplateSimple {
  key: number;
  commodity: string;
  customers: string;
}

interface TemplatesTableData {
  total: number;
  templates: TableTemplateRow[];
}

export const useTableTemplateRow = (): FetchGet<TemplatesTableData, TemplatesGetParams> => (
  useTemplatesGet((data): TemplatesTableData => ({
    total: data.meta.totalItems,
    templates: data.items
      .filter((template) => !template.hide)
      .map((template): TableTemplateRow => ({
        id: template.id,
        key: template.id,
        name: template.name,
        status: template.status,
        customers: template.customers.map((customer) => customer.name).join(', '),
        commodity: template.commodity?.name || '',
      })),
  }))
);

interface PaginateTemplateData {
  id: number;
  type: null;
  name: string;
  hide: boolean;
  banner: Banner;
  status: boolean;
  created: string;
  updated: string;
  customers: CustomerSimple[];
  commodity: CommoditySimple | null;
  templateFields: TemplateField[];
}

export function useTemplatesGet<D = PagingDataResponse<PaginateTemplateData>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, TemplatesGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'templates',
    { decorateData, autoStart: false },
  );
}

export interface TemplateField {
  id: number;
  name: string;
  type: TemplateFieldTypes;
  value: { id: number; value: string; }[];
  required: boolean;
  position: number;
}

export const useTemplatesCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchCreate('templates')
);

export const useTemplatesUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchUpdate('templates')
);

export interface Banner {
  id: number;
  entity: string;
  created: string;
  updated: string;
  mimeType: string;
  fileSize: number;
  fileName: string;
  originalName: string;
  url: string;
}

export interface TemplateFieldValue {
  id: number;
  name: string;
  type: TemplateFieldTypes;
  value: string;
  required: boolean;
  position: number;
}

export interface TemplateId extends TemplateSimple {
  banner: Banner | null;
  created: string;
  updated: string;
  customers: CustomerSimple[];
  commodity: CommoditySimple | null;
  templateFields: TemplateFieldValue[];
}

export const useTemplatesId = (): FetchGetId<TemplateId> => useFetchGetId('templates', '', { autoStart: false });

export const useTemplateFieldCount = (): FetchGetId<number> => (
  useFetchGetId('templates/template-field-count', '', { autoStart: false })
);

export const useTemplateGeneric = (): FetchGetId<TemplateId> => (
  useFetchGetId('templates/generic/qc', '', { autoStart: false })
);
