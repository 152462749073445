import React from 'react';
import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Content from '../../components/Pages/Users';
import UsersProvider from '../../context/users';
import { displayName } from '../../config';

const Users: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Users`;

  return (
    <Layout>
      <PageHeader title="Users" />
      <UsersProvider>
        <Content />
      </UsersProvider>
    </Layout>
  );
};

export default Users;
