import { Record, RecordUser } from './records';
import { Template } from './templates';
import { ReportStatus } from '../enums/reports';
import { ReportCategory } from './lpReports';
import { CustomerSimple } from './customers';
import { CommoditySimple } from './commodities';
import { Warehouse, WarehouseSimple } from './warehouses';
import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';
import { ReportImages } from '../context/qcReportUpdateTemplate';

export interface QcReport {
  id: number;
  record: Record;
  status: string;
  created: string;
  updated: string;
  category: ReportCategory;
  generatedAt: string | null;
  attachments: string | null;
  reportTemplates: Template[];
  completedBy: RecordUser | null;
  user: RecordUser;
  pdfUrl: string;
}

interface ReportsGetParams {
  id?: number;
  type?: string;
  page?: number;
  limit?: number;
  bolAwb?: string;
  status?: string;
  search?: string;
  typeId?: number;
  orderBy?: 'ASC' | 'DESC';
  updated?: string[];
  endDate?: string;
  template?: number;
  recordId?: number;
  warehouse?: number;
  startDate?: string;
  generatedAt?: string[];
  orderByColumn?: 'bolAwb' | 'id' | 'warehouse' | 'templates'
    | 'attachments' | 'generatedAt' | 'type' | 'updated' | 'status';
}

export interface QcTableReportRow {
  id: number;
  key: number;
  record: Record;
  status: string;
  bolAwb: string;
  created: string;
  updated: string;
  templates: string;
  warehouse: Warehouse;
  generatedAt: string | null;
  attachments: string | null;
  completedBy: RecordUser | null;
  user: RecordUser;
  pdfUrl: string;
}

interface ReportsTableData {
  total: number;
  qcReports: QcTableReportRow[];
}

export const useTableQcReportRow = (): FetchGet<ReportsTableData, ReportsGetParams> => (
  useQcReportsGet((data: PagingDataResponse<QcReport>): ReportsTableData => ({
    total: data.meta.totalItems,
    qcReports: data.items.map((report: QcReport): QcTableReportRow => ({
      id: report.id,
      key: report.id,
      bolAwb: report.record.bolAwb,
      record: report.record,
      status: report.status,
      updated: report.updated,
      created: report.created,
      templates: report.reportTemplates.map((template) => template.template.name).join(', '),
      warehouse: report.record.warehouse,
      generatedAt: report.generatedAt,
      attachments: report.attachments,
      completedBy: report.completedBy,
      user: report.user,
      pdfUrl: report.pdfUrl,
    })),
  }))
);

export function useQcReportsGet<D = PagingDataResponse<QcReport>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, ReportsGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'reports/qc',
    { decorateData, autoStart: false },
  );
}

export interface TemplateFieldValue {
  id: number;
  value: string | string[] | number | number[];
  required?: boolean;
}

export interface QcReportCreateSuccess {
  id: number;
  success: boolean;
}

export const useQcReportCreate = (): FetchCreate<QcReportCreateSuccess, DefaultFetchError, FormData> => (
  useFetchCreate('reports/qc')
);

export interface QcReportsRecord {
  id: number;
  po: string;
  bolAwb: string;
  created: string;
  uid: string | null;
  customer: CustomerSimple;
  warehouse: WarehouseSimple;
  commodities: CommoditySimple[];
  type: string;
}

export interface ReportTemplateField {
  id: number;
  value: string;
  templateField: {
    id: number;
    name: string;
    type: string;
    value: string;
    required: boolean;
  };
}

export interface QcReportTemplateInWork {
  id: number;
  role: string;
  login: string;
  email: string;
  status: boolean;
  updated: string;
  created: string;
  lastName: string;
  firstName: string;
}

export interface QcReportTemplate {
  id: number;
  images: ReportImages[];
  galleryImages: string;
  inWork: QcReportTemplateInWork | null;
  created: string;
  template: {
    id: number;
    name: string;
    banner: { id: number; url: string; } | null;
  };
  reportTemplateFields: ReportTemplateField[];
  addDataStump: boolean;
  prevImages: number[];
  cutTemplateFields?: TemplateFieldValue[];
}

interface QcReportType {
  id: number;
  name: string;
  icon: string;
  status: string;
}

export interface QcReportsId {
  id: number;
  type: QcReportType | null;
  status: ReportStatus;
  record: QcReportsRecord;
  created: string;
  updated: string;
  addDataStump: boolean;
  category: ReportCategory;
  attachments: string | null;
  generatedAt: string | null;
  firstGeneratedAt: string | null;
  reportTemplates: QcReportTemplate[];
  pdfUrl: string;
}

export const useQcReportsId = (): FetchGetId<QcReportsId> => useFetchGetId('reports/qc', '', { autoStart: false });
export const useQcDeleteTemplates = (): FetchDelete<number> => useFetchDelete('reports/templates/');

export interface ReportGenerateParama {
  report: number;
  images: number[];
  parentLogNumber?: string;
  addDataStump: boolean;
}

export const useReportGenerate = (): FetchCreate<FetchSuccess, DefaultFetchError, ReportGenerateParama> => (
  useFetchCreate('reports/generate')
);

interface ReportSendParams {
  id: number;
  emails: string[];
}

export const useReportSend = (): FetchCreate<FetchSuccess, DefaultFetchError, ReportSendParams> => (
  useFetchCreate('reports/send')
);

// id: ${id}/in-work
export const useReportTemplateInWork = (): FetchUpdate<FetchSuccess> => (
  useFetchUpdate('reports/templates/')
);
