import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import TableUsers from '../../Users/Table';
import GeneralInfo from './GeneralInfo';
import { useAuth } from '../../../../context/auth';
import { useContextWarehouseUpdate } from '../../../../context/warehouseUpdate';

const WarehouseUpdate: React.FC = () => {
  const { isRoleEnough } = useAuth();
  const navigate = useNavigate();
  const { isDisabled, onSave, values, setValue, warehouseId, warehouseUpdate } = useContextWarehouseUpdate();

  const routes = [
    {
      path: '/warehouses',
      breadcrumbName: 'Warehouses',
    },
    {
      breadcrumbName: warehouseId?.data?.name || 'Edit Warehouse',
    },
  ];

  return (
    <>
      <PageHeader
        title={warehouseId?.data?.name || 'Edit Warehouse'}
        extra={isRoleEnough('admin') ? (
          <Button type="primary" disabled={isDisabled} onClick={onSave} loading={warehouseUpdate?.loading}>
            Save changes
          </Button>
        ) : undefined}
        onBack={() => navigate(-2)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <GeneralInfo />
        <TableUsers
          params={{ status: 'true' }}
          selectedRows={values.users}
          onRowSelection={(users) => setValue({ users })}
        />
      </Content>
    </>
  );
};

export default WarehouseUpdate;
