import React from 'react';
import { Content } from 'antd/es/layout/layout';

import TableTemplates from './Table';
// import { IcStyle } from './../LpReportTypes/Icons/data';

const Templates: React.FC = (): JSX.Element => (
  <Content>
    <TableTemplates />
  </Content>
);

export default Templates;
