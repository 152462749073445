import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Space, Typography } from 'antd';

import dayjs from 'dayjs';
import GalleryTemplate from './Gallery';
import ImagesList from './ImageLists';
import { ReportImages } from '../../../context/qcReportUpdateTemplate';
import { useContextLpReportGenerate } from '../../../context/lpReportGenerate';
import { QcReportTemplate } from '../../../hooks/qcReports';
import { useUnsavedRegenerate } from '../../../context/unsaveRegenerate';
import { useContextQcReportGenerate } from '../../../context/qcReportGenerate';
import { useReportTemplateUpdate } from '../../../hooks/reportTemplate';

import styles from '../../Pages/LpReports/Regenerate/LpReportRegenerateStep/index.module.less';

interface IRegenerateReportMedia {
  template: QcReportTemplate;
  values: QcReportTemplate;
  setValues: (value: QcReportTemplate) => void;
  isQcReport: boolean;
  currentStep: number;
}

export interface IState {
  imagesList: ReportImages[];
  selected: ReportImages[];
}

const RegenerateReportMedia: FC<IRegenerateReportMedia> = ({
  values,
  isQcReport,
  currentStep,
  setValues,
}) => {
  const {
    timestamp: qcTimeStamp = false,
    handleTimestamp: handleQcTimeStamp,
    initialSelectedIds: initialQcSelectedIds,
  } = useContextQcReportGenerate();

  const {
    timestamp: lpTimeStamp = false,
    handleTimestamp: handleLpTimeStamp,
    initialSelectedIds: initialLpSelectedIds,
  } = useContextLpReportGenerate();

  const { unsavedChanges } = useUnsavedRegenerate();
  const [selectedImages, setSelectedImages] = useState<ReportImages[]>([]);
  const [isAllSelect, setIsAllSelect] = useState<boolean | undefined>(
    undefined,
  );
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const reportTemplateUpdate = useReportTemplateUpdate();

  useEffect(() => {
    if (isAllSelect === undefined) return;
    setValues({
      ...values,
      prevImages: isAllSelect ? values.images.map((el) => el.id) : [],
    });
  }, [isAllSelect]);

  const handleVisibility = () => setModalVisibility(!modalVisibility);

  const handleChecked = (checked: boolean, images: ReportImages[]) => {
    setIsAllSelect(undefined);

    const imagesId = images.map((el) => el.id);

    setValues({
      ...values,
      prevImages: checked
        ? [...values.prevImages, ...imagesId]
        : values.prevImages.filter((id) => !imagesId.includes(id)),
    });
  };

  useEffect(() => {
    if (
      !Object.keys(isQcReport ? initialQcSelectedIds : initialLpSelectedIds)
        .length
    ) {
      return;
    }

    const initialsIds = isQcReport
      ? initialQcSelectedIds[currentStep]
      : initialLpSelectedIds[0];

    const isEqual = initialsIds.length === values.prevImages.length
      && initialsIds.every((id) => values.prevImages.includes(id));

    unsavedChanges(isEqual);
  }, [values.prevImages, initialQcSelectedIds, initialLpSelectedIds]);

  const handleFetchGalleryImages = async (galleryImages: ReportImages[]) => {
    const galleryIds = galleryImages.map((el) => el.id);

    const formData = new FormData();

    formData.append('galleryImages', JSON.stringify(galleryIds));

    await reportTemplateUpdate.fetch(formData, values.id);
  };

  const handleGalleryImages = async (payload: {
    galleryImages: ReportImages[];
  }) => {
    const newImagesList = [...values.images, ...payload.galleryImages].reduce(
      (result, currentObject) => {
        const existingObject = result.find(
          (obj) => obj.id === currentObject.id,
        );

        if (!existingObject) {
          result.push(currentObject);
        }

        return result;
      },
      [] as ReportImages[],
    );

    const sorterNewImagesList = [...newImagesList].sort((a, b) => {
      const dateA = dayjs(a.created);
      const dateB = dayjs(b.created);

      return dateA.isBefore(dateB) ? -1 : 1;
    });

    setValues({
      ...values,
      images: sorterNewImagesList,
    });

    await handleFetchGalleryImages(payload.galleryImages);
  };

  useEffect(() => {
    if (!values.images.length) return;

    if (isAllSelect === undefined) {
      setSelectedImages(
        values.images.filter((el) => values.prevImages.includes(el.id)),
      );

      return;
    }

    setSelectedImages(isAllSelect ? values.images : []);
  }, [isAllSelect, values]);

  return (
    <Card
      title="Media"
      style={{ marginBottom: 24 }}
      extra={(
        <Space>
          <Checkbox
            checked={isQcReport ? qcTimeStamp : lpTimeStamp}
            onChange={({ target: { checked } }) => isQcReport
              ? handleQcTimeStamp(checked)
              : handleLpTimeStamp(checked)}
          >
            Add timestamp to photos
          </Checkbox>
          <Button
            ghost
            onClick={() => {
              setIsAllSelect(!isAllSelect);
            }}
          >
            {isAllSelect ? 'Deselect all' : 'Select All'}
          </Button>
        </Space>
      )}
    >
      <div className={styles.actions}>
        <Typography>
          Please deselect photos that should not be included in the report.
        </Typography>
        <Button type="primary" onClick={handleVisibility}>
          Upload from Gallery
        </Button>
      </div>
      <GalleryTemplate
        title={isQcReport ? 'Qc gallery' : 'Lp gallery'}
        handleGalleryImages={handleGalleryImages}
        selected={values.images.filter((el) => el.entity === 'gallery')}
        handleVisibility={handleVisibility}
        modalVisibility={modalVisibility}
      />
      <ImagesList
        timestamp={isQcReport ? qcTimeStamp : lpTimeStamp}
        isQcReport={isQcReport}
        templateId={values.id}
        selected={selectedImages}
        handleChecked={handleChecked}
        setValues={setValues}
        currentStep={currentStep}
        values={values}
      />
    </Card>
  );
};

export default RegenerateReportMedia;
