import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError, FetchSuccess } from '../types';
import { FetchCreate, getMessageInError } from '../hooks/fetch';
import { useWarehouseCreate, WarehouseCreateParams } from '../hooks/warehouses';

type ValuesChangeType = { name: string; } | { users: number[]; } | { location: string; }

interface WarehousesCreateContext {
  onSave: () => void;
  values: WarehouseCreateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  warehouseCreate: FetchCreate<FetchSuccess, FetchError, WarehouseCreateParams> | null;
}

export const initialValues: WarehouseCreateParams = {
  name: '',
  users: [],
  location: '',
};

const defaultValue: WarehousesCreateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  warehouseCreate: null,
};

export const WarehousesCreateContext = createContext<WarehousesCreateContext>(defaultValue);
interface WarehousesCreateProviderProps {
  children: React.ReactNode;
}
const WarehousesCreateProvider: React.FC<WarehousesCreateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const warehouseCreate = useWarehouseCreate();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [values, setValues] = useState<WarehouseCreateParams>(initialValues);
  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    setIsDisabled(!values.name);
  }, [values.name]);

  const onSave = () => {
    if (!isDisabled) {
      const newValues: WarehouseCreateParams = {
        name: values.name,
        users: values.users,
      };

      if (values.location) {
        newValues.location = values.location;
      }

      warehouseCreate.fetch(newValues);
    }
  };

  const clear = () => {
    setValues(initialValues);
  };

  useEffect(() => {
    if (warehouseCreate.data && !warehouseCreate.error) {
      message.success('Created!');
      navigate('/warehouses', { replace: true });
      clear();
    }
  }, [warehouseCreate.data]);

  useEffect(() => {
    if (warehouseCreate.error) {
      message.error(getMessageInError(warehouseCreate.error));
      warehouseCreate.clearError();
    }
  }, [warehouseCreate.error]);

  return (
    <WarehousesCreateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        warehouseCreate,
      }}
    >
      {children}
    </WarehousesCreateContext.Provider>
  );
};

export default WarehousesCreateProvider;

export const useContextWarehouseCreate = (): WarehousesCreateContext => useContext(WarehousesCreateContext);
