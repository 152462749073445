import React from 'react';
import Sider from 'antd/es/layout/Sider';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';

import Logo from './Logo';
import MenuCommon from './Menu';
import { useAuth } from '../../../../context/auth';

import styles from './index.module.less';
import { useUnsavedRegenerate } from '../../../../context/unsaveRegenerate';

const SiderCommon: React.FC = (): JSX.Element => {
  const { user, logout } = useAuth();
  const { condition, regenerateUnsavedModal } = useUnsavedRegenerate();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!condition) {
      e.preventDefault();
      regenerateUnsavedModal('/records', true);
    } else {
      logout();
    }
  };

  return (
    <Sider width={200} style={{ background: 'none' }}>
      <div className={styles.sider}>
        <Logo />
        <MenuCommon />
        <div className={styles.logoutWrapper}>
          <Link to="Sign-in">
            <Button className={styles.logout} onClick={(e) => handleClick(e)}>
              <span className="clip">
                {`${user.firstName} ${user.lastName}`}
              </span>
              <LogoutOutlined />
            </Button>
          </Link>
        </div>
      </div>
    </Sider>
  );
};

export default SiderCommon;
