import { UploadFile } from 'antd/es/upload/interface';

import { Record, RecordUser } from './records';
import { Template } from './templates';
import { Warehouse } from './warehouses';
import { LpReportType } from './lpReportTypes';
import { ReportStatus } from '../enums/reports';
import { QcReportTemplate } from './qcReports';
import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';
import { ReportImages } from '../context/qcReportUpdateTemplate';

export type ReportCategory = 'qc' | 'lp'

export interface LpReport {
  id: number;
  type: LpReportType;
  record: Record;
  status: string;
  created: string;
  updated: string;
  category: ReportCategory;
  generatedAt: string | null;
  attachments: string | null;
  reportTemplates: Template[];
  completedBy: RecordUser | null;
  user: RecordUser;
  pdfUrl: string;
}

interface ReportsGetParams {
  id?: number;
  type?: string;
  page?: number;
  limit?: number;
  search?: string;
  bolAwb?: string;
  status?: string;
  typeId?: number;
  orderBy?: 'ASC' | 'DESC';
  updated?: string[];
  endDate?: string;
  template?: number;
  recordId?: number;
  startDate?: string;
  warehouse?: number;
  generatedAt?: string[];
  orderByColumn?: 'bolAwb' | 'id' | 'warehouse' | 'templates'
    | 'attachments' | 'generatedAt' | 'type' | 'updated' | 'status';
}

export interface LpTableReportRow {
  id: number;
  key: number;
  typeId: LpReportType;
  record: Record;
  status: string;
  bolAwb: string;
  created: string;
  updated: string;
  warehouse: Warehouse;
  generatedAt: string | null;
  attachments: string | null;
  completedBy: RecordUser | null;
  user: RecordUser;
  pdfUrl: string;
}

interface ReportsTableData {
  total: number;
  lpReports: LpTableReportRow[];
}

export const useTableLpReportRow = (): FetchGet<ReportsTableData, ReportsGetParams> => (
  useLpReportsGet((data: PagingDataResponse<LpReport>): ReportsTableData => ({
    lpReports: data.items.map((report: LpReport): LpTableReportRow => ({
      id: report.id,
      key: report.id,
      typeId: report.type,
      bolAwb: report.record.bolAwb,
      record: report.record,
      status: report.status,
      updated: report.updated,
      created: report.created,
      warehouse: report.record.warehouse,
      generatedAt: report.generatedAt,
      attachments: report.attachments,
      completedBy: report.completedBy,
      user: report.user,
      pdfUrl: report.pdfUrl,
    })),
    total: data.meta.totalItems,
  }))
);

export function useLpReportsGet<D = PagingDataResponse<LpReport>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, ReportsGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'reports/lp',
    { decorateData, autoStart: false },
  );
}

export interface ReportTemplateField {
  id: number;
  value: string | string[] | number | number[];
}

export interface LpReportCreateParams {
  type: number;
  record: number;
  images: UploadFile[];
  template?: number;
  reportTemplateFields?: ReportTemplateField[];
  galleryImages: ReportImages[];
}

export interface ResponseLpReport {
  id: number;
  success: boolean;
}

export const useLpReportCreate = (): FetchCreate<ResponseLpReport, DefaultFetchError, FormData> => (
  useFetchCreate('reports/lp')
);

export interface LpReportsId {
  id: number;
  type: LpReportType | null;
  status: ReportStatus;
  addDataStump: boolean;
  record: {
    id: number;
    po: string;
    type: string;
    bolAwb: string;
    created: string;
    customer: { id: number; name: string; email: string; status: boolean; };
    warehouse: { id: number; name: string; location: string; status: boolean; };
    commodities: { id: number; name: string; status: boolean; }[];
    awb_record_id: number | null; // TODO remove
    loading_notes: string;
    log_number: string;
    uid: string | null;
  };
  created: string;
  updated: string;
  category: ReportCategory;
  attachments: string[] | null;
  generatedAt: string | null;
  firstGeneratedAt: string | null;
  reportTemplates: QcReportTemplate[];
  pdfUrl:string;
}

export const useLpReportsId = (): FetchGetId<LpReportsId> => useFetchGetId('reports/lp', '', { autoStart: false });

export const useLpReportUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchUpdate('reports/lp')
);
