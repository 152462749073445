import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Search from 'antd/es/input/Search';
import { ParamsType } from '@ant-design/pro-provider';
import { PlusOutlined } from '@ant-design/icons/lib';
import { Key, SortOrder } from 'antd/es/table/interface';
import { Link, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Badge, Button, FormInstance, message } from 'antd';
import Table from '../../../Common/Table';
import SelectCustomer from '../../../Common/SelectCustomer';
import SelectCommodity from '../../../Common/SelectCommodity';
import RecordsStatuses, { RecordFilterStatuses } from '../../../../enums/records';
import { ReportCategory } from '../../../../hooks/lpReports';
import { getMessageInError } from '../../../../hooks/fetch';
import { useContextRecordCreate } from '../../../../context/recordCreate';
import { ModalState, ModalTypes } from '../../../../types';
import { TableRecordRow, useTableRecordRow } from '../../../../hooks/records';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { useAuth } from '../../../../context/auth';
import SelectWarehouseMulti from '../../../Common/SelectWarehouseMulti';

interface TableRecords {
  params?: {
    reportCategory?: ReportCategory;
    type?: 'awb' | 'bol' | 'misc';
    cutStartDate?: string;
    cutEndDate?: string;
    warehouse?: string | undefined;
  };
  isReassign?: boolean;
  hideInSearch?: Array<keyof TableRecordRow> | undefined;
  hideInTable?: Array<keyof TableRecordRow> | undefined;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  exceptRecordId?: number;
  removeLink?: boolean;
}

const TableRecords: React.FC<TableRecords> = ({
  params = {},
  openModal,
  selectedRows,
  hideInSearch,
  hideInTable,
  onRowSelection,
  isReassign,
  exceptRecordId,
  removeLink,
}): JSX.Element => {
  const { isRoleEnough } = useAuth();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const recordsGet = useTableRecordRow();
  const { recordCreate } = useContextRecordCreate();
  const [searchParams, setSearchParams] = useSearchParams();
  const typePrevValue = useRef(searchParams.get('type') || 'awb' || 'misc');

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');
  const [hideLink, setHideLink] = useState<boolean>();

  useEffect(() => {
    setHideLink(removeLink);
  }, []);

  const onSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      orderBy: searchParams.get('orderBy') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      search: value,
      po: searchParams.get('po') || '',
      key: searchParams.get('key') || '',
      status: searchParams.get('status') || '',
      bolAwb: searchParams.get('bolAwb') || '',
      customer: searchParams.get('customer') || '',
      commodity: searchParams.get('commodity') || '',
      warehouse: searchParams.get('warehouse') || '',
      created: searchParams.get('created') || '',
      type: searchParams.get('type') || '',
      cutStartDate: searchParams.get('type') !== 'bol' ? searchParams.get('cutStartDate') || '' : '',
      cutEndDate: searchParams.get('type') !== 'bol' ? searchParams.get('cutEndDate') || '' : '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    if ((recordCreate?.data && !recordCreate?.error)) {
      actionRef.current?.reload();
    }
  }, [recordCreate?.data]);

  useEffect(() => {
    if (recordsGet.error) {
      message.error(getMessageInError(recordsGet.error));
      recordsGet.clearError();
    }
  }, [recordsGet.error]);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      value={searchValue}
      style={{ width: 264 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
    (openModal && isRoleEnough('manager')) || (openModal && searchParams.get('type') === 'misc') ? (
      <Button
        key="button"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => openModal({ type: ModalTypes.create })}
      >
        Add New
      </Button>
    ) : null,
  ], [searchValue, onSearch]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableRecordRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: current ? `${pageSize}` : '10',
      ...{ ...args, type: searchParams.get('type') || args.type },
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...{ ...args, type: searchParams.get('type') || args.type }, ...getSorterParams(sorter) });

    let paramsType: 'awb' | 'bol' | 'misc' = 'awb';

    if (searchParams.get('type') || params.type) {
      paramsType = (searchParams.get('type') || params.type) as 'awb' | 'bol' | 'misc';
    } else if (params) {
      paramsType = params.reportCategory === 'qc' ? 'bol' : 'awb';
    }

    return recordsGet.fetch({
      ...newParams,
      ...{
        ...params,
        type: paramsType,
      },
      warehouse: params.warehouse || undefined,
      warehouse_ids: newParams.warehouse?.split(','),
    }).then((data) => {
      if (data) {
        const { records, total } = data;

        return ({
          data: records.filter((el) => el.id !== exceptRecordId) || [],
          success: true,
          total,
        });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    let newParamsType = params.reportCategory === 'qc' ? 'bol' : 'awb';
    const beforeSubmitType = isReassign ? (beforeSubmitParams.type || params.type) : beforeSubmitParams.type;

    const cutStartDateParam = (beforeSubmitType === 'awb' || beforeSubmitType === undefined)
      ? beforeSubmitParams.cutStartDate || moment().format('YYYY-MM-DD') : '';
    const cutEndDateParam = (beforeSubmitType === 'awb' || beforeSubmitType === undefined)
      ? beforeSubmitParams.cutEndDate || moment().format('YYYY-MM-DD') : '';

    if (beforeSubmitType !== 'awb' && beforeSubmitType !== undefined) {
      newParamsType = beforeSubmitType;
    }

    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
      type: newParamsType,
      cutStartDate: cutStartDateParam,
      cutEndDate: cutEndDateParam,
    });

    /** before submit we change previous type value to that, which was set by user */
    typePrevValue.current = beforeSubmitType || 'awb';

    if (params.type) {
      setSearchParams({
        ...newParams,
        ...{
          ...params,
          type: (beforeSubmitParams.type === 'misc' ? beforeSubmitType as string : params.type),
        },
      });

      return {
        ...newParams,
        ...{
          ...params,
          type: searchParams.get('type') === 'misc' ? searchParams.get('type') as string : params.type,
        },
      };
    }

    if (newParamsType === 'misc') {
      delete newParams.po;
      delete newParams.log_number;
      delete newParams.userGroup;
      delete newParams.customer;
      delete newParams.commodities;
      delete newParams.cut_off_date;
    } else if (newParamsType === 'awb') {
      delete newParams.userGroup;
      delete newParams.commodities;
    } else {
      delete newParams.log_number;
      delete newParams.cut_off_date;
    }

    setSearchParams(newParams);

    return newParams;
  }, [searchParams.get('search')]);

  const getRecordBadge = useCallback((status: string): JSX.Element => {
    switch (status) {
      case 'in progress':
        return <Badge status="warning" text={RecordsStatuses.inProgress} />;
      case 'completed':
        return <Badge status="success" text={RecordsStatuses.completed} />;
      case 'expired':
        return <Badge status="default" text={RecordsStatuses.expired} />;
      case 'not started':
        return <Badge status="error" text={RecordsStatuses.notStarted} />;
      default:
        return <></>;
    }
  }, []);

  useEffect(() => {
    if (params.reportCategory === 'qc') {
      formRef.current?.setFieldsValue({ type: searchParams.get('type') ? searchParams.get('type') : 'bol' });

      return;
    }
    formRef.current?.setFieldsValue({ type: searchParams.get('type') ? searchParams.get('type') : 'awb' });
  }, []);

  const columns: ProColumns<TableRecordRow>[] = [
    {
      title: () => {
        if (searchParams.get('type') === 'awb') return 'AWB#';
        if (searchParams.get('type') === 'bol') return 'BOL#';
        if (searchParams.get('type') === 'misc') return 'Title';

        return 'Record#';
      },
      dataIndex: 'bolAwb',
      sorter: true,
      order: 2,
      renderText: (bolAwb, { id }) => {
        if (hideLink === true) {
          return bolAwb;
        }

        return <Link to={`/records/update/${id}`}>{bolAwb}</Link>;
      },
      onCell: (id) => ({
        onClick: () => {
          if (hideLink === true) {
            if (onRowSelection) {
              onRowSelection([id.id]);
            }
          }
        },
      }),
    },
    {
      title: 'Type ',
      dataIndex: 'type',
      sorter: false,
      hideInSearch: hideInSearch?.includes('type'),
      hideInTable: hideInTable?.includes('type'),
      valueEnum: {
        awb: params.reportCategory === 'qc' ? null : 'AWB',
        bol: 'BOL',
        misc: 'MISC',
      },
      order: 3,
      formItemProps: {
        label: 'Record type',
        style: params.reportCategory === 'lp' ? { pointerEvents: 'none' } : {},
      },
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      sorter: true,
      hideInSearch: hideInSearch?.includes('warehouse'),
      hideInTable: hideInTable?.includes('warehouse'),
      renderText: (warehouse) => warehouse?.name || '',
      renderFormItem: (_, { ...config }) => (
        <SelectWarehouseMulti
          {...config}
          placeholder="Please select"
          value={typeof config.value !== 'string' ? config.value : JSON.parse(`[${config.value}]`)}
          mode="multiple"
        />
      ),
    },
    {
      title: 'PO#',
      dataIndex: 'po',
      sorter: true,
      hideInTable: searchParams.get('type') === 'misc',
      hideInSetting: searchParams.get('type') === 'misc',
      hideInSearch: searchParams.get('type') === 'misc',
      hideInForm: searchParams.get('type') === 'misc',
    },
    {
      title: 'Log#',
      dataIndex: 'log_number',
      sorter: true,
      hideInTable: searchParams.get('type') !== 'awb',
      hideInSearch: searchParams.get('type') !== 'awb',
      hideInSetting: searchParams.get('type') !== 'awb',
      hideInForm: searchParams.get('type') !== 'awb',
    },
    {
      title: 'User Group',
      dataIndex: 'userGroup',
      sorter: false,
      hideInTable: searchParams.get('type') !== 'bol',
      hideInSetting: searchParams.get('type') !== 'bol',
      hideInSearch: searchParams.get('type') !== 'bol',
      hideInForm: searchParams.get('type') !== 'bol',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: true,
      renderText: (customer) => customer?.name || '',
      renderFormItem: (_, { ...config }) => (
        <SelectCustomer {...config} />
      ),
      hideInTable: searchParams.get('type') === 'misc',
      hideInSetting: searchParams.get('type') === 'misc',
      hideInSearch: searchParams.get('type') === 'misc',
      hideInForm: searchParams.get('type') === 'misc',
    },
    {
      title: 'Commodity',
      dataIndex: 'commodities',
      sorter: true,
      search: { transform: (() => 'commodity') },
      renderText: (name, { commodities }) => commodities?.map((item) => (item.name)).join(', '),
      renderFormItem: (_, { value }) => <SelectCommodity value={value} />,
      hideInTable: searchParams.get('type') !== 'bol',
      hideInSearch: searchParams.get('type') !== 'bol',
      hideInSetting: searchParams.get('type') !== 'bol',
      hideInForm: searchParams.get('type') !== 'bol',
    },
    {
      title: 'Cut off date',
      dataIndex: 'cut_off_date',
      valueType: 'dateRange',
      sorter: true,
      search: {
        transform: (dates: [string, string]) => ({ cutStartDate: dates[0], cutEndDate: dates[1] }),
      },
      render: (_, { cut_off_date }) => cut_off_date
        ? moment(cut_off_date).tz('Etc/UTC').format('MM/DD/YYYY, hh:mm A') : '',
      hideInTable: searchParams.get('type') !== 'awb',
      hideInSearch: searchParams.get('type') !== 'awb',
      hideInSetting: searchParams.get('type') !== 'awb',
      hideInForm: searchParams.get('type') !== 'awb',
      formItemProps: {
        className: searchParams.get('bolAwb') ? 'cut-off-date-field-disabled' : '',
      },
      fieldProps: {
        allowClear: false, // Use fieldProps to disable the clear button
      },
      initialValue: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    },
    {
      title: 'Date added :',
      dataIndex: 'created',
      valueType: 'dateRange',
      sorter: true,
      search: { transform: ((dates: [string, string]) => ({ startDate: dates[0], endDate: dates[1] })) },
      render: (_, { created }) => created ? moment(created).format('MM/DD/YYYY, hh:mm A') : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
      renderText: getRecordBadge,
      valueEnum: RecordFilterStatuses,
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TableRecordRow>
      formRef={formRef}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      headerTitle="Records list"
      rowSelection={onRowSelection ? { ...rowSelection, type: 'radio' } : false}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      scroll={{ x: 1000, y: 500 }}
      beforeSearchSubmit={beforeSearchSubmit}
    />
  );
};

TableRecords.defaultProps = {
  params: undefined,
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
  hideInSearch: undefined,
  hideInTable: undefined,
  isReassign: false,
  exceptRecordId: undefined,
  removeLink: false,
};

export default TableRecords;
