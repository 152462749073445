import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';
import clsx from 'clsx';
import { useAuth } from '../../../../../context/auth';
import { required } from '../../../../../utils/inputRules';
import SelectCustomer from '../../../../Common/SelectCustomer';
import SelectWarehouse from '../../../../Common/SelectWarehouse';
import SelectCommodityMulti from '../../../../Common/SelectCommodityMulti';
import { initialValues, useContextRecordUpdate } from '../../../../../context/recordUpdate';
import NotFoundContent from '../../../../Common/NotFoundContent';
import SelectAwbRecordMulti from '../../../../Common/SelectAwbRecordMulti';
import { ReplayIcon } from '../../../../Common/Icon';
import confirm from '../../../../Common/ModalConfirm';
import error from '../../../../Common/ModalError';
import { EnumRecordStatus, getAWStatus, sendAWStatus } from './requests';

import styles from './index.module.less';

const GeneralInfo: React.FC = (): JSX.Element => {
  const { isRoleEnough, user, token } = useAuth();
  const { onSave, values, setValue, recordId } = useContextRecordUpdate();

  const [status, setStatus] = useState<EnumRecordStatus>();

  const [form] = Form.useForm();

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        ...values,
        customer: values.customer || null,
        warehouse: values.warehouse || null,
        awb_record_ids: values.awb_record_ids || null,
      });
    }
  }, [form, values]);

  useEffect(() => {
    moment.tz.setDefault('Etc/UTC');

    return () => {
      moment.tz.setDefault();
    };
  }, []);

  useEffect(() => {
    if (recordId?.data?.id && recordId.data.uid && !status) {
      handleGetStatus();
    }
  }, [recordId?.data?.id]);

  const handleGetStatus = async () => {
    if (!recordId?.data?.id || status) return;

    const res = await getAWStatus(recordId?.data?.id, token || '');

    if (res.status) {
      setStatus((res.status || EnumRecordStatus['in-progress']).toString().replace(' ', '-') as EnumRecordStatus);

      return;
    }

    error({
      title: 'Error',
      content: 'Something went wrong',
      okText: 'Ok',
    });
  };

  const handleSetStatusToAWork = async () => {
    if (!recordId?.data?.id) return;

    const res = await sendAWStatus(recordId?.data?.id, token || '');

    if (res) {
      setStatus(EnumRecordStatus.completed);

      return;
    }

    error({
      title: 'Error',
      content: 'Something went wrong',
      okText: 'Ok',
    });
  };

  return (
    <Card
      title={(
        <div className={styles.cardHead}>
          <div>General info</div>
          {recordId?.data?.type === 'bol' && status && recordId.data.uid ? (
            <Button
              type="primary"
              className={clsx(styles.setStatusBtn, styles[status || ''])}
              icon={status === EnumRecordStatus['in-progress'] ? <ReplayIcon /> : null}
              onClick={(e) => status === EnumRecordStatus['in-progress'] ? (
                confirm({
                  title: 'Changing the BOL QC Status in A-Works',
                  content: 'Are you sure you want to change the QC Status in A-Works?',
                  okText: 'Yes',
                  cancelText: 'No',
                  icon: <WarningOutlined />,
                  onOk: handleSetStatusToAWork,
                })) : e.preventDefault()}
            >
              {status === EnumRecordStatus['in-progress'] ? 'Task in Progress' : `Task ${status || ''}`}
            </Button>
          ) : null}
        </div>
      )}
      style={{ marginBottom: '24px' }}
      loading={recordId?.loading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        className="warehouse_create"
        initialValues={{ ...initialValues, status: recordId?.data?.status }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="warehouse" label="Warehouse" rules={required}>
              <SelectWarehouse
                disabled
                onChange={(value) => setValue({ warehouse: value as number })}
                activeOnly
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="type" label="Record type" rules={required}>
              <Select
                options={[{ value: 'awb', label: 'AWB' }, { value: 'bol', label: 'BOL' }]}
                allowClear
                disabled
                placeholder="Please select"
                notFoundContent={<NotFoundContent message="Report type not found." />}
              />
            </Form.Item>
          </Col>
          <Col span={8} />
          <Col span={8}>
            <Form.Item name="bolAwb" label={values.type !== 'misc' ? 'Record#' : 'Title'} rules={required}>
              <Input
                type="text"
                disabled={(user.role === 'user' as string && values.type !== 'misc')
                  || (user.role !== 'user' as string && !!recordId?.data?.uid)}
                onChange={({ target: { value } }) => setValue({ bolAwb: value })}
                placeholder="Please enter"
              />
            </Form.Item>
          </Col>
          {values.type !== 'misc' ? (
            <Col span={8}>
              <Form.Item name="po" label="PO#">
                <Input
                  type="text"
                  onChange={({ target: { value } }) => setValue({ po: value })}
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  placeholder="Please enter"
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col span={8} />
          <Col span={8}>
            {values.type === 'awb' && (
              <Form.Item name="log_number" label="Log#">
                <Input
                  type="text"
                  placeholder="Please enter"
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  onChange={({ target: { value } }) => setValue({ log_number: value })}
                />
              </Form.Item>
            )}
            {values.type === 'bol' && (
              <Form.Item name="awb_record_ids" label="Parent AWB records">
                <SelectAwbRecordMulti
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  onChange={(value) => setValue({ awb_record_ids: value as number[] })}
                />
              </Form.Item>
            )}
          </Col>
          {values.type !== 'misc' ? (
            <Col span={8}>
              <Form.Item name="customer" label="Customer">
                <SelectCustomer
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  onChange={(value) => setValue({ customer: value as number })}
                  activeOnly
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col span={8} />
          <Col span={8}>
            {values.type === 'awb' && (
              <Form.Item label={<span className={styles.requiredElem}>Cut off date</span>} rules={required}>
                <DatePicker
                  name="cut_off_date"
                  value={values.cut_off_date ? moment(values.cut_off_date) : null}
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  showTime={{ format: 'HH:mm' }}
                  style={{ width: '100%' }}
                  format="MM-DD-YYYY HH:mm"
                  showNow={false}
                  allowClear={false}
                  onChange={(dateTime) => {
                    setValue({ cut_off_date: `${dateTime?.format()}` });
                  }}
                />
              </Form.Item>
            )}
            {values.type === 'bol' && (
              <Form.Item name="userGroup" label="User group">
                <Input
                  type="text"
                  placeholder="Please enter"
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  onChange={({ target: { value } }) => setValue({ userGroup: value })}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={8}>
            {values.type === 'awb' && (
              <Form.Item name="loading_notes" label="Loading notes">
                <Input
                  type="text"
                  placeholder="Please enter"
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  onChange={({ target: { value } }) => setValue({ loading_notes: value })}
                  style={{ fontWeight: 600 }}
                />
              </Form.Item>
            )}
            {values.type === 'bol' && (
              <Form.Item name="commodities" label="Commodities">
                <SelectCommodityMulti
                  disabled={!(isRoleEnough('manager') && !recordId?.data?.uid)}
                  onChange={(commodity) => setValue({ commodities: commodity as number[] })}
                  activeOnly
                />
              </Form.Item>
            )}
          </Col>
          <Col span={8} />
          {values.type === 'awb' && recordId?.data?.get_bol_records && (
          <>
            {recordId?.data?.get_bol_records.map((bolRecord) => (
              <Col key={bolRecord.id} span={4}>
                <Link to={`/records/update/${bolRecord.id}`}>
                  {`View BOL ${bolRecord.bolAwb} Record`}
                </Link>
              </Col>
            ))}
          </>
          )}
          {values.type === 'bol' && recordId?.data?.get_awb_records && (
            <>
              {recordId?.data?.get_awb_records.map((awbRecord) => (
                <Col key={awbRecord.id} span={4}>
                  <Link to={`/records/update/${awbRecord.id}`}>
                    {`View AWB ${awbRecord.bolAwb} Record`}
                  </Link>
                </Col>
              ))}
            </>
          )}
        </Row>
      </Form>
    </Card>
  );
};

export default GeneralInfo;
