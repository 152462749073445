import { Customer } from './customers';
import { Commodity } from './commodities';
import { Warehouse } from './warehouses';
import { ReportCategory } from './lpReports';
import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface Record {
  id: number;
  po: string;
  types: string[]; // TODO remove with RecordReportType
  bolAwb: string;
  created: string;
  customer: Customer;
  warehouse: Warehouse;
  status: string;
  commodities?: Commodity[];
  type: 'bol' | 'awb' | 'misc' | '';
  cut_off_date?: string;
  log_number?: string;
  userGroup?: string;
  loading_notes?: string;
  awb_record_id?: number; // TODO remove?
  reports: {
    attachments: string | null;
    category: string;
    created: string;
    generatedAt: string | null;
    id: number;
    status: string;
    updates: string;
  };
  uid: string | null;
  user: RecordUser;
}

export interface RecordCreateParams {
  warehouse: number;
  type: 'awb' | 'bol' | 'misc' | '';
  bolAwb: string;
  po?: string;
  log_number?: string;
  userGroup?: string;
  cut_off_date?: string;
  customer: number;
  commodities?: number[];
  loading_notes?: string;
  awb_record_ids?: number[];
  title?: string;
}

export interface RecordSuccess {
  id: number;
  success: boolean;
}

export const useRecordCreate = (): FetchCreate<RecordSuccess, DefaultFetchError, RecordCreateParams> => (
  useFetchCreate('records')
);

export interface RecordsGetParams {
  po?: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  bolAwb?: string;
  orderBy?: 'ASC' | 'DESC';
  created?: string;
  customer?: string;
  warehouse?: string;
  warehouse_ids?: string[];
  commodities?: string;
  orderByColumn?: 'po' | 'status' | 'bolAwb' | 'customer' | 'commodities' | 'warehouse' | 'created'
    | 'cut_off_date' | 'type' | 'log_number';
  reportCategory?: ReportCategory;
  type?: 'awb' | 'bol' | 'misc' | '';
  cutStartDate?: string;
  cutEndDate?: string;
}

export interface RecordUser {
  id: number;
  firstName: string;
  lastName: string;
  login: string;
  email?: string | null;
  role: string;
  status: boolean;
  updated: Date;
  created: Date;
}

export interface TableRecordRow {
  id: number;
  po: string;
  key: number;
  bolAwb: string;
  created: string;
  customer: Customer;
  warehouse: Warehouse;
  status: string;
  commodities: Commodity[];
  type: 'bol' | 'awb' | 'misc' | '';
  cut_off_date: string;
  log_number: string;
  userGroup: string;
  loading_notes: string;
  user: RecordUser;
  uid: string | null;
  // awb_record_id: number;
}

interface RecordsTableData {
  total: number;
  records: TableRecordRow[];
}

export const useTableRecordRow = (): FetchGet<RecordsTableData, RecordsGetParams> => (
  useRecordsGet((data: PagingDataResponse<Record>): RecordsTableData => ({
    total: data.meta.totalItems,
    records: data.items.map((record: Record): TableRecordRow => ({
      id: record.id,
      po: record.po,
      key: record.id,
      status: record.status,
      bolAwb: record.bolAwb,
      created: record.created,
      customer: record.customer,
      warehouse: record.warehouse,
      commodities: record?.commodities || [],
      type: record.type,
      cut_off_date: record?.cut_off_date || '',
      log_number: record?.log_number || '',
      userGroup: record?.userGroup || '',
      loading_notes: record?.loading_notes || '',
      uid: record.uid || '',
      user: record.user,
    })),
  }))
);

export function useRecordsGet<D = PagingDataResponse<Record>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, RecordsGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'records',
    { decorateData, autoStart: false },
  );
}

export interface RecordUpdateParams {
  types?: string[]; // TODO remove with RecordReportType
  warehouse?: number;
  type?: 'awb' | 'bol' | 'misc' | '';
  bolAwb?: string;
  po?: string;
  log_number?: string;
  userGroup?: string;
  cut_off_date?: string;
  customer?: number;
  commodities?: number[];
  loading_notes?: string;
  awb_record_ids?: number[];
}

export const useRecordUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, RecordUpdateParams> => (
  useFetchUpdate('records')
);

export interface TableRecordReportRow {
  id: number;
  key: number;
  name: string;
  status: string;
  category: ReportCategory;
  attachments: string | null;
  pdfUrl?: string;
}

interface RecordCustomer {
  id: number;
  name: string;
  email: string;
  status: boolean;
}

interface RecordWarehouse {
  id: number;
  name: string;
  status: boolean;
  location: string;
}

interface RecordCommodity {
  id: number;
  name: string;
  status: boolean;
}

interface RecordLoggers {
  id: number;
  user: {
    id: number;
    login: string;
    email: string;
    role: string;
  };
  event: string;
  entity: string;
  created: string;
  updated: string;
  entityId: number;
}

interface RecordReportType { // TODO remove with types[]
  id: number;
  name: string;
  icon: string;
  status: string;
  reports: {
    id: number;
    status: string;
    created: string;
    category: ReportCategory;
    attachments: string | null;
    pdfUrl?: string;
  }[];
  category: string;
  reportStatus: string;
}

export interface RecordReports {
  id: number;
  status: string;
  created: string;
  category: ReportCategory;
  attachments: string | null;
  type: {
    icon: string;
    id: number;
    name: string;
    status: string;
  } | null;
  generatedAt: string | null;
  pdfUrl?: string;
}

export interface BolAwbChildren {
  id: number;
  bolAwb: string;
  log_number: string;
}

export interface RecordId {
  id: number;
  po: string;
  types: RecordReportType[]; // TODO remove with RecordReportType
  reports: RecordReports[];
  bolAwb: string;
  created: string;
  loggers: RecordLoggers[];
  customer: RecordCustomer | null;
  warehouse: RecordWarehouse | null;
  status: string;
  commodities: RecordCommodity[];
  type: 'bol' | 'awb' | 'misc' | '';
  cut_off_date: string;
  log_number: string;
  loading_notes: string;
  awb_record_id: number;
  get_bol_records: BolAwbChildren[]; // for look into AWB
  get_awb_records: BolAwbChildren[]; // for look into BOL
  uid: string | null;
  userGroup?: string;
}

export const useRecordId = (): FetchGetId<RecordId> => useFetchGetId('records', '', { autoStart: false });
