import React from 'react';
import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Content from '../../components/Pages/Customers';
import { displayName } from '../../config';
import CustomersProvider from '../../context/customers';

const Customers: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Customers`;

  return (
    <Layout>
      <PageHeader title="Customers" />
      <CustomersProvider>
        <Content />
      </CustomersProvider>
    </Layout>
  );
};

export default Customers;
