import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import GeneralInfo from '../GeneralInfo';
import QualityReportFields from '../QualityReportFields';
import { useContextTemplateCreate } from '../../../../context/templateCreate';

const routes = [
  {
    path: '/templates',
    breadcrumbName: 'Templates',
  },
  {
    breadcrumbName: 'Add New Template',
  },
];

const TemplateCreate: React.FC = () => {
  const navigate = useNavigate();
  const { values, onSave, setValue, isDisabled, templateCreate } = useContextTemplateCreate();

  return (
    <>
      <PageHeader
        title="Add New Template"
        extra={(
          <Button type="primary" disabled={isDisabled} onClick={onSave} loading={templateCreate?.loading}>
            Save
          </Button>
        )}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <GeneralInfo values={values} onSave={onSave} setValue={setValue} />
        <QualityReportFields
          onSave={onSave}
          templateFields={values.templateFields}
          setTemplateFields={(templateFields) => setValue({ templateFields })}
        />
      </Content>
    </>
  );
};

export default TemplateCreate;
