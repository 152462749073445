import React, { createContext, useContext } from 'react';

import { DefaultFetchError, FetchCreate } from '../hooks/fetch';
import { RecordCreateParams, RecordSuccess, useRecordCreate } from '../hooks/records';

interface RecordCreateContext {
  recordCreate: FetchCreate<RecordSuccess, DefaultFetchError, RecordCreateParams> | null;
}

const defaultValue = {
  recordCreate: null,
};

interface RecordCreateProviderProps {
  children: React.ReactNode;
}
export const RecordCreateContext = createContext<RecordCreateContext>(defaultValue);

const RecordCreateProvider: React.FC<RecordCreateProviderProps> = ({ children }) => {
  const recordCreate = useRecordCreate();

  return (
    <RecordCreateContext.Provider value={{ recordCreate }}>
      {children}
    </RecordCreateContext.Provider>
  );
};

export default RecordCreateProvider;

export const useContextRecordCreate = (): RecordCreateContext => useContext(RecordCreateContext);
