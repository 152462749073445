import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { useReportTemplateCreate } from '../hooks/reportTemplate';
import { FetchError, FetchSuccess } from '../types';
import { FetchCreate, FetchGetId, getMessageInError } from '../hooks/fetch';
import { QcReportsId, TemplateFieldValue, useQcReportsId } from '../hooks/qcReports';
import { ReportImages } from './qcReportUpdateTemplate';

interface ReportTemplateCreateParams {
  report: number;
  images: UploadFile[];
  template: number;
  reportTemplateFields: TemplateFieldValue[];
  galleryImages: ReportImages[];
}

type ValuesChangeType = { template: number; } | { reportTemplateFields: TemplateFieldValue[]; }
  | { images: UploadFile[]; } | { galleryImages: ReportImages[]; };

interface QcReportCreateTemplateContext {
  onSave: () => void;
  values: ReportTemplateCreateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  qcReportsId: FetchGetId<QcReportsId> | null;
  setIsDisabled: (disabled: boolean) => void;
  reportTemplateCreate: FetchCreate<FetchSuccess, FetchError, FormData> | null;
}

export const initialValues: ReportTemplateCreateParams = {
  images: [],
  report: 0,
  template: 0,
  reportTemplateFields: [],
  galleryImages: [],
};

const defaultValue: QcReportCreateTemplateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  qcReportsId: null,
  setIsDisabled: () => undefined,
  reportTemplateCreate: null,
};

interface IQcReportCreateTemplateProvider {
  children: React.ReactNode;
}

export const QcReportCreateTemplateContext = createContext<QcReportCreateTemplateContext>(defaultValue);

const QcReportCreateTemplateProvider: FC<IQcReportCreateTemplateProvider> = ({ children }) => {
  const navigate = useNavigate();
  const qcReportsId = useQcReportsId();
  const reportTemplateCreate = useReportTemplateCreate();
  const { id: reportId = 0 } = useParams<{ id: string; }>();

  const [values, setValues] = useState<ReportTemplateCreateParams>({ ...initialValues, report: +reportId });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  const onSave = () => {
    if (!isDisabled) {
      const formData = new FormData();

      values.images.map(({ originFileObj }) => originFileObj).forEach((image) => {
        if (image) {
          formData.append('images', image as File);
        }
      });
      formData.append('report', values.report.toString());
      formData.append('template', values.template.toString());
      formData.append('reportTemplateFields', JSON.stringify(values.reportTemplateFields));

      if (values.galleryImages.length) {
        formData.append('galleryImages', JSON.stringify(values.galleryImages.map((el) => el.id)));
      }

      reportTemplateCreate.fetch(formData);
    }
  };

  useEffect(() => {
    if (reportTemplateCreate.data && !reportTemplateCreate.error) {
      navigate(`/reports/qc/${values.report}`, { replace: true });
    }
  }, [reportTemplateCreate.data]);

  useEffect(() => {
    if (reportTemplateCreate.error) {
      message.error(getMessageInError(reportTemplateCreate.error));
      reportTemplateCreate.clearError();
    }
  }, [reportTemplateCreate.error]);

  useEffect(() => {
    if (reportId) {
      qcReportsId.fetch(undefined, reportId);
    }
  }, [reportId]);

  useEffect(() => {
    if (qcReportsId.error) {
      message.error(getMessageInError(qcReportsId.error));
      qcReportsId.clearError();
    }
  }, [qcReportsId.error]);

  return (
    <QcReportCreateTemplateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        qcReportsId,
        setIsDisabled,
        reportTemplateCreate,
      }}
    >
      {children}
    </QcReportCreateTemplateContext.Provider>
  );
};

export default QcReportCreateTemplateProvider;

export const useContextQcReportCreateTemplate = (): QcReportCreateTemplateContext => (
  useContext(QcReportCreateTemplateContext)
);
