import React from 'react';
import { Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Content from '../../components/Pages/Templates';
import { displayName } from '../../config';

const Templates: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Templates`;

  return (
    <Layout>
      <PageHeader title="Templates" />
      <Content />
    </Layout>
  );
};

export default Templates;
