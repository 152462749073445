import { Form, Input, message, Modal } from 'antd';
import React, { useCallback, useEffect } from 'react';

import Loading from '../../../Common/Loading';
import SelectIcon from '../SelectIcon';
import { required } from '../../../../utils/inputRules';
import SelectLpReportStatus from '../../../Common/SelectLpReportStatus';
import { getMessageInError } from '../../../../hooks/fetch';
import { useContextLpReportTypes } from '../../../../context/lpReportTypes';
import { LpReportTypeUpdateParams, useLpReportTypeId } from '../../../../hooks/lpReportTypes';
import { objOfAwDocTypes, objOfAwTypes } from '../Create';

interface ModalUpdateLpReportType {
  id: number;
  close: () => void;
  isOpen: boolean;
}

const ModalUpdateLpReportType: React.FC<ModalUpdateLpReportType> = ({ isOpen, close, id }): JSX.Element => {
  const [form] = Form.useForm();
  const lpReportTypeId = useLpReportTypeId();
  const { lpReportTypeUpdate } = useContextLpReportTypes();

  useEffect(() => {
    if (id) {
      lpReportTypeId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (form && lpReportTypeId.data && !lpReportTypeId.error && form) {
      form.setFieldsValue({
        icon: lpReportTypeId.data.icon,
        name: lpReportTypeId.data.name,
        status: lpReportTypeId.data.status,
      });
    }
  }, [form, lpReportTypeId.data]);

  useEffect(() => {
    if (lpReportTypeId.error) {
      getMessageInError(lpReportTypeId.error);
      lpReportTypeId.clearError();
    }
  }, [lpReportTypeId.error]);

  const onSubmit = useCallback((values: LpReportTypeUpdateParams) => {
    const foundAwTypesValue = Object.entries(objOfAwTypes).find((item) => (
      values.name.toLowerCase() === item[0].toLocaleLowerCase()));
    const foundAwDocTypesValue = Object.entries(objOfAwDocTypes).find((item) => (
      values.name.toLowerCase() === item[0].toLocaleLowerCase()));

    lpReportTypeUpdate?.fetch({
      ...values,
      awType: foundAwTypesValue ? foundAwTypesValue[1] : '',
      awDocType: foundAwDocTypesValue ? foundAwDocTypesValue[1] : '',
    }, id);
  }, [lpReportTypeUpdate, id]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      onSubmit(values);
    });
  };

  useEffect(() => {
    if (lpReportTypeUpdate?.data && !lpReportTypeUpdate.error) {
      message.success('Updated!');
      close();
    }
  }, [lpReportTypeUpdate?.data]);

  useEffect(() => {
    if (lpReportTypeUpdate?.error) {
      message.error(getMessageInError(lpReportTypeUpdate.error));
      lpReportTypeUpdate.clearError();
    }
  }, [lpReportTypeUpdate?.error]);

  return (
    <Modal
      onOk={handleOk}
      title="Edit LP Report Type"
      okText="Save changes"
      open={isOpen}
      onCancel={close}
      afterClose={form.resetFields}
      confirmLoading={lpReportTypeUpdate?.loading}
      destroyOnClose
    >
      <Loading absolute visible={lpReportTypeId.loading} />
      <Form
        form={form}
        name="lp_report_create_update"
        layout="horizontal"
        labelCol={{ span: 5 }}
        autoComplete="false"
      >
        <Form.Item name="name" label="Name" rules={required}>
          <Input type="text" placeholder="Please enter" />
        </Form.Item>
        <Form.Item name="icon" label="Image" rules={required}>
          <SelectIcon value="icon" initialValue={lpReportTypeId.data?.icon || ''} />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={required}>
          <SelectLpReportStatus />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalUpdateLpReportType;
