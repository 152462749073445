import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import React, { useEffect, useState } from 'react';

import Routes from './routes';
import Auth, { AuthContext } from './context/auth';

import './App.less';

function App(): JSX.Element {
  const [, refreshAuth] = useState({});

  useEffect(() => {
    Auth.change = (): void => refreshAuth({});
  }, []);

  return (
    <AuthContext.Provider value={Auth}>
      <ConfigProvider
        theme={{
          token: { colorPrimary: '#1F803F', colorLink: '#1f803f', fontFamily: "'Manrope', sans-serif" },
          components: { Menu:
            {
              itemBorderRadius: 0,
              fontFamily: 'Manrope',
              itemMarginBlock: 7,
              itemHoverBg: 'transparent',
              itemHoverColor: '#1F803F',
            } },
          hashed: false,
        }}
        locale={enUS}
      >
        <Routes />
      </ConfigProvider>
    </AuthContext.Provider>
  );
}

export default App;
