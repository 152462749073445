import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import QcReportGenerateStep from './QcReportGenerateStep';
import { QcReportTemplate, TemplateFieldValue } from '../../../../hooks/qcReports';
import { getMessageInError } from '../../../../hooks/fetch';
import { useReportTemplateUpdate } from '../../../../hooks/reportTemplate';
import { useContextQcReportGenerate } from '../../../../context/qcReportGenerate';
import { useRecordId } from '../../../../hooks/records';

import styles from '../../LpReports/Generate/index.module.less';
import { queryFilterParams } from '../../../../utils';
import confirm from '../../../Common/ModalConfirm';

const QcReportGenerate: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportTemplateUpdate = useReportTemplateUpdate();
  const recordGetId = useRecordId();
  const {
    isDisabled,
    onSave,
    values,
    setValue,
    qcReportsId,
    reportGenerate,
    changeBolLogNumber,
    timestamp,
    handleTimestamp,
  } = useContextQcReportGenerate();
  // const [triggerOnSave, setTriggerOnSave] = useState<boolean>(false);
  //
  // useEffect(() => {
  //   if (triggerOnSave) {
  //     setTriggerOnSave(false);
  //     onSave();
  //   }
  // }, [onSave, triggerOnSave]);

  const [, setLogNumOptions] = useState<{ id: number; bolAwb: string; log_number: string; }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (qcReportsId?.data?.record) {
      recordGetId.fetch(undefined, qcReportsId.data.record.id);
    }
  }, [qcReportsId?.data?.record]);

  useEffect(() => {
    if (recordGetId.data?.get_awb_records) {
      setLogNumOptions(recordGetId.data?.get_awb_records);

      if (recordGetId.data?.get_awb_records.length < 2) {
        changeBolLogNumber(recordGetId.data?.get_awb_records[0]?.log_number || '-');
      }
    }
  }, [recordGetId.data]);

  const [currentStep, setCurrentStep] = useState(0);
  const [cutTemplateFields, setCutTemplateFields] = useState<TemplateFieldValue[]>([]);

  useEffect(() => {
    if (qcReportsId?.data) {
      setCutTemplateFields(qcReportsId.data.reportTemplates[currentStep]?.reportTemplateFields?.map(
        ({ value, templateField }) => ({ id: templateField.id, value }),
      ));
    }
  }, [qcReportsId?.data]);

  // const checkChanges = (callBack: () => void) => {
  //   const copy = values[currentStep];
  //   const formData = new FormData();
  //
  //   copy.images.forEach(({ id }) => {
  //     formData.append('oldImages[]', id.toString());
  //   });
  //   formData.append('report', qcReportsId?.data?.id.toString() || '');
  //   formData.append('template', copy.template.id.toString());
  //   formData.append('reportTemplateFields', JSON.stringify(cutTemplateFields.length
  //     ? cutTemplateFields.map(
  //       ({ value, id }) => ({ id, value }),
  //     ) : copy.reportTemplateFields.map(
  //       ({ value, templateField }) => ({ id: templateField.id, value }),
  //     )));
  //
  //   reportTemplateUpdate?.fetch(formData, copy.id).then(() => setCutTemplateFields([])).then(callBack);
  // };

  useEffect(() => {
    setSearchParams(queryFilterParams({
      valid: '',
      step: `${currentStep}`,
    }));
  }, [currentStep]);

  const next = () => {
    setCurrentStep(currentStep + 1);
    // checkChanges(() => setCurrentStep(currentStep + 1));
  };

  useEffect(() => {
    if (reportTemplateUpdate.error) {
      getMessageInError(reportTemplateUpdate.error);
      reportTemplateUpdate.clearError();
    }
  }, [reportTemplateUpdate.error]);

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const routes = [
    {
      path: '/reports/qc',
      breadcrumbName: 'QC Reports',
    },
    {
      path: `/reports/qc/${qcReportsId?.data?.id}`,
      breadcrumbName: qcReportsId?.data?.record.bolAwb
        ? `${qcReportsId?.data?.record.type.toUpperCase()} ${qcReportsId?.data?.record.bolAwb}` : 'Report details',
    },
    {
      breadcrumbName: 'Generate QC Report',
    },
  ];

  return (
    <>
      <PageHeader
        title={(
          <div className={styles.wrapBackText}>
            <span>Generate</span>
            <span className={styles.smallText}>
              {qcReportsId?.data?.reportTemplates[currentStep]?.template?.name || ''}
            </span>
          </div>
        )}
        extra={(
          <>
            {currentStep > 0 && <Button type="default" onClick={prev}>Back</Button>}
            {currentStep < values.length - 1 && (
              <Button
                type="primary"
                onClick={next}
                loading={reportTemplateUpdate.loading}
                disabled={values[currentStep].reportTemplateFields.length
                  ? (!(values[currentStep].images.length && values[currentStep].reportTemplateFields
                    .filter((field) => field.templateField.required)
                    .every((field) => !!field.value)
                  )) : !(values[currentStep].images.length && searchParams.get('valid'))}
              >
                Next Step
                {' '}
                {`${currentStep + 1}/${values.length}`}
              </Button>
            )}
            {(currentStep === values.length - 1) && (
              <Button
                type="primary"
                disabled={isDisabled || recordGetId.loading}
                onClick={() => confirm({
                  onOk: async () => {
                    setIsLoading(true);
                    await onSave();
                    setIsLoading(false);
                  },
                  title: qcReportsId?.data?.record.uid ? 'Send to A-works?' : 'Generate PDF?',
                  okText: qcReportsId?.data?.record.uid ? 'Send to A-works' : 'Generate PDF',
                  content: 'Please make sure the report is completed correctly before generating.',
                })}
                loading={isLoading || reportGenerate?.loading || reportTemplateUpdate.loading}
              >
                {qcReportsId?.data?.record?.uid ? 'Send to A-works' : 'Generate PDF'}
              </Button>
            )}
          </>
        )}
        onBack={() => navigate(-2)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        {values.length ? (
          <QcReportGenerateStep
            key={values[currentStep].id}
            timestamp={timestamp}
            handleTimestamp={handleTimestamp}
            values={values.find(({ id }) => id === values[currentStep].id) as QcReportTemplate}
            template={qcReportsId?.data?.reportTemplates.find((reportTemplate) => (
              reportTemplate.id === values[currentStep].id
            )) as QcReportTemplate}
            setValues={(value) => setValue(values.map((templateValues) => {
              if (templateValues.id === values[currentStep].id) {
                return value;
              }

              return templateValues;
            }))}
            cutTemplateFields={cutTemplateFields}
            setCutTemplateValues={(value) => setCutTemplateFields(value)}
            onSave={next}
          />
        ) : <Card loading />}
      </Content>
    </>
  );
};

export default QcReportGenerate;
