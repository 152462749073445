import clsx from 'clsx';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Card, Empty, message, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import { useAuth } from '../../../../context/auth';
import DetailsCard from '../../../Common/DetailsCard';
import TableLoggers from '../../../Common/TableLoggers';
import ModalSendReport from '../../../Common/ModalSendReport';
import { useLpReportsId } from '../../../../hooks/lpReports';
import { QcReportTemplate } from '../../../../hooks/qcReports';
import { getMessageInError } from '../../../../hooks/fetch';
import { capitalizeFirstLetter } from '../../../../utils';
import { ReportStatus, ReportStatuses } from '../../../../enums/reports';

import styles from './index.module.less';

const LpReportProfile: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const lpReportId = useLpReportsId();
  const { id: reportId = '0' } = useParams<{ id: string; }>();

  const [modal, setModal] = useState<number>(0);

  useEffect(() => {
    if (reportId) {
      lpReportId.fetch(undefined, reportId);
    }
  }, [reportId]);

  useEffect(() => {
    if (lpReportId.error) {
      message.error(getMessageInError(lpReportId.error));
      lpReportId.clearError();
    }
  }, [lpReportId.error]);

  const onGenerate = () => {
    navigate(`/reports/lp/generate/${reportId}`, { replace: false });
  };

  const routes = [
    {
      path: '/reports/lp/',
      breadcrumbName: 'LP Reports',
    },
    {
      breadcrumbName: lpReportId?.data?.record.bolAwb ? `AWB ${lpReportId?.data?.record.bolAwb}` : 'Report details',
    },
  ];

  const getLpReportTagClass = (status: ReportStatus): string => {
    switch (status) {
      case 'in progress':
        return 'status-in-progress';
      case 'completed':
        return 'status-completed';
      case 'expired':
        return 'status-expired';
      case 'not started':
        return 'status-not-started';
      default:
        return '';
    }
  };

  const getHeaderExtra = (status: string) => {
    switch (status) {
      case 'completed':
        return (
          <>
            <Button
              rel="noreferrer"
              type="primary"
              href={`${lpReportId.data?.pdfUrl}`}
              target="_blank"
            >
              Download PDF
            </Button>
            <Button type="primary" onClick={() => setModal(lpReportId.data?.id || 0)}>Send</Button>
          </>
        );
      case 'in progress':
        return (
          <Button type="primary" onClick={onGenerate}>
            Review
          </Button>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <PageHeader
        title={lpReportId?.data?.record.bolAwb ? (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '16px' }}>
              {`AWB ${lpReportId?.data?.record.bolAwb}`}
            </div>
            {lpReportId?.data.status ? (
              <div>
                <Tag
                  className={getLpReportTagClass(lpReportId?.data.status)}
                  style={getLpReportTagClass(lpReportId?.data.status) !== ''
                    ? { borderRadius: '35px', background: 'transparent' }
                    : { color: '#FAAD14', borderColor: '#FAAD14', borderRadius: '35px', background: 'transparent' }}
                >
                  {capitalizeFirstLetter(ReportStatuses[lpReportId?.data.status])}
                </Tag>
              </div>
            ) : null}
          </div>
        ) : 'Report details'}
        extra={getHeaderExtra(lpReportId.data?.status || '')}
        onBack={() => navigate(-1)}
        breadcrumb={(
          <Breadcrumb>
            {routes.map(({ path, breadcrumbName }) => (
              <Breadcrumb.Item key={breadcrumbName}>
                {path ? <Link to={path}>{breadcrumbName}</Link> : breadcrumbName}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      />
      <Content>
        <ModalSendReport isOpen={!!modal} close={() => setModal(0)} reportId={lpReportId.data?.id || 0} category="lp" />
        {lpReportId.data?.reportTemplates.length ? (
          <div className={clsx(styles.cardWrapper, 'template-card')}>
            {lpReportId.data?.reportTemplates.map((template: QcReportTemplate) => (
              <DetailsCard
                isQcReport={false}
                firstGeneratedAt={lpReportId.data?.firstGeneratedAt || ''}
                key={template.id}
                reportId={reportId}
                onEdit={() => navigate(
                  `/reports/lp/update/${reportId}/template/${template.id}`, // /reports/lp/update/${reportId}
                  { replace: false },
                )}
                inWork={template.inWork && template.inWork.id !== user.id ? template.inWork : null}
                itemIcon={lpReportId?.data?.type?.icon || ''}
                onPreview={() => navigate(`/reports/lp/${reportId}/template/${template.id}`, { replace: false })}
                imagesCount={template.prevImages?.length || template.images.length}
                itemTypeName={lpReportId?.data?.type?.name || 'Type name'}
                reportStatus={lpReportId.data?.status || 'not started'}
              />
            ))}
          </div>
        ) : (
          <Card style={{ marginBottom: 24 }} bodyStyle={{ padding: 16 }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Card>
        )}
        <TableLoggers entity="reports" />
      </Content>
    </>
  );
};

export default LpReportProfile;
