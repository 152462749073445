import { Form, Input, message, Modal } from 'antd';
import React, { useCallback, useEffect } from 'react';

import Loading from '../../../../Common/Loading';
import SelectStatus from '../../../../Common/SelectStatus';
import { required } from '../../../../../utils/inputRules';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useContextCommodities } from '../../../../../context/commodities';
import { CommoditiesUpdateParams, useCommoditiesId } from '../../../../../hooks/commodities';

interface ModalUpdateCommodity {
  id: number;
  isOpen: boolean;
  close: () => void;
}

const ModalUpdateCommodity: React.FC<ModalUpdateCommodity> = ({ id, isOpen, close }) => {
  const { commodityUpdate } = useContextCommodities();
  const commodityId = useCommoditiesId();
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      commodityId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (form && commodityId.data && !commodityId.error && form) {
      form.setFieldsValue({
        name: commodityId.data.name,
        status: commodityId.data.status,
      });
    }
  }, [form, commodityId.data]);

  useEffect(() => {
    if (commodityId.error) {
      getMessageInError(commodityId.error);
      commodityId.clearError();
    }
  }, [commodityId.error]);

  const onSubmit = useCallback((values: CommoditiesUpdateParams) => {
    commodityUpdate?.fetch(values, id);
  }, [commodityUpdate, id]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      onSubmit(values);
    });
  };

  useEffect(() => {
    if (commodityUpdate?.data && !commodityUpdate?.error) {
      message.success('Updated!');
      close();
    }
  }, [commodityUpdate?.data]);

  useEffect(() => {
    if (commodityUpdate?.error) {
      message.error(getMessageInError(commodityUpdate?.error));
      commodityUpdate.clearError();
    }
  }, [commodityUpdate?.error]);

  return (
    <Modal
      onOk={handleOk}
      title="Edit Commodity"
      okText="Save changes"
      open={isOpen}
      centered
      onCancel={close}
      afterClose={form.resetFields}
      confirmLoading={commodityUpdate?.loading}
      destroyOnClose
    >
      <Loading absolute visible={commodityId.loading} />
      <Form
        form={form}
        name="commodity_create"
        layout="horizontal"
        labelCol={{ span: 5 }}
        autoComplete="false"
      >
        <Form.Item name="name" label="Name" rules={required}>
          <Input type="text" placeholder="Please enter" />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={required}>
          <SelectStatus />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalUpdateCommodity;
