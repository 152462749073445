import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError, FetchSuccess } from '../types';
import { FetchGetId, FetchUpdate, getMessageInError } from '../hooks/fetch';
import { LpReportsId, useLpReportsId, useLpReportUpdate } from '../hooks/lpReports';
import { useReportTemplateGetId, useReportTemplateUpdate } from '../hooks/reportTemplate';
import { ReportImages } from './qcReportUpdateTemplate';

type ValuesChangeType = { type: number; } | { images: UploadFile[]; } | { oldImages: ReportImages[]; }
  | { galleryImages: ReportImages[]; };

export interface LpReportUpdateParams {
  type: number;
  report: number;
  images: UploadFile[];
  oldImages: ReportImages[];
  galleryImages: ReportImages[];
}

interface LpReportUpdateContext {
  onSave: () => void;
  values: LpReportUpdateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  lpReportsId: FetchGetId<LpReportsId> | null;
  lpReportUpdate: FetchUpdate<FetchSuccess, FetchError, FormData> | null;
  reportTemplateUpdate: FetchUpdate<FetchSuccess, FetchError, FormData> | null;
  setIsDisabled: (disabled: boolean) => void;
  setIsRouteToHome: (isRouteToHome: boolean) => void;
}

export const initialValues: LpReportUpdateParams = {
  type: 0,
  report: 0,
  images: [],
  oldImages: [],
  galleryImages: [],
};

const defaultValue: LpReportUpdateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  setIsDisabled: () => undefined,
  lpReportsId: null,
  lpReportUpdate: null,
  reportTemplateUpdate: null,
  setIsRouteToHome: () => undefined,
};

export const LpReportUpdateContext = createContext<LpReportUpdateContext>(defaultValue);
interface LpReportUpdateProviderProps {
  children: React.ReactNode;
}
const LpReportUpdateProvider: React.FC<LpReportUpdateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const lpReportsId = useLpReportsId();
  const lpReportUpdate = useLpReportUpdate();
  const reportTemplateGetId = useReportTemplateGetId();
  const reportTemplateUpdate = useReportTemplateUpdate();
  const { id: reportId = 0, templateId = 0 } = useParams<{ id: string; templateId: string; }>();

  const [values, setValues] = useState<LpReportUpdateParams>({
    ...initialValues,
    report: +reportId,
  });

  useEffect(() => {
    const isValid = values.type && (values.images.length || values.oldImages.length);

    setIsDisabled(!isValid);
  }, [values]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isRouteToHome, setIsRouteToHome] = useState<boolean>(true);

  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  const onSave = () => {
    if (!isDisabled) {
      const formData = new FormData();

      formData.append('report', values.report.toString());

      values.images.map(({ originFileObj }) => originFileObj).forEach((image) => {
        if (image) {
          formData.append('images', image as File);
        }
      });

      if (values.oldImages.length) {
        values.oldImages.forEach((image) => {
          formData.append('oldImages[]', image.id.toString());
        });
      } else {
        formData.append('oldImages[]', '0');
      }

      if (values.galleryImages.length) {
        formData.append('galleryImages', JSON.stringify(values.galleryImages.map((el) => el.id)));
      }

      reportTemplateUpdate.fetch(formData, templateId);
    }
  };

  useEffect(() => {
    if (reportTemplateUpdate.data && !reportTemplateUpdate.error && isRouteToHome) {
      navigate(`/reports/lp/${reportId}`, { replace: true });
    }
    setIsRouteToHome(true);
  }, [reportTemplateUpdate.data]);

  useEffect(() => {
    if (reportTemplateUpdate.error) {
      message.error(getMessageInError(reportTemplateUpdate.error));
      reportTemplateUpdate.clearError();
    }
  }, [reportTemplateUpdate.error]);

  useEffect(() => {
    if (templateId) {
      reportTemplateGetId.fetch(undefined, templateId);
    }
  }, [templateId]);

  useEffect(() => {
    if (reportTemplateGetId.data && !reportTemplateGetId.error) {
      setValues({
        galleryImages: reportTemplateGetId.data.images.filter((el) => el.entity === 'gallery') || [],
        oldImages: reportTemplateGetId.data.images,
        images: [],
        report: +reportId,
        type: lpReportsId.data?.type?.id ? +lpReportsId.data.type.id : 0,
      });
    }
  }, [reportTemplateGetId.data]);

  useEffect(() => {
    if (reportTemplateGetId.error) {
      message.error(getMessageInError(reportTemplateGetId.error));
      reportTemplateGetId.clearError();
    }
  }, [reportTemplateGetId.error]);

  useEffect(() => {
    if (reportId) {
      lpReportsId.fetch(undefined, reportId);
    }
  }, [reportId]);

  useEffect(() => {
    if (lpReportsId.data && !lpReportsId.error) {
      setValues({
        ...values,
        type: lpReportsId.data.type?.id ? +lpReportsId.data.type.id : 0,
        report: lpReportsId.data.id,
        images: [],
        oldImages: lpReportsId.data.reportTemplates[0].images,
      });
    }
  }, [lpReportsId.data]);

  useEffect(() => {
    if (lpReportsId.error) {
      message.error(getMessageInError(lpReportsId.error));
      lpReportsId.clearError();
    }
  }, [lpReportsId.error]);

  return (
    <LpReportUpdateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        setIsDisabled,
        lpReportsId,
        lpReportUpdate,
        reportTemplateUpdate,
        setIsRouteToHome,
      }}
    >
      {children}
    </LpReportUpdateContext.Provider>
  );
};

export default LpReportUpdateProvider;

export const useContextLpReportUpdate = (): LpReportUpdateContext => (
  useContext(LpReportUpdateContext)
);
