import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { FetchError } from '../types';
import { FetchCreate, getMessageInError } from '../hooks/fetch';
import { QcReportCreateSuccess, useQcReportCreate } from '../hooks/qcReports';
import { ReportImages } from './qcReportUpdateTemplate';

export interface QcReportCreateParams {
  record: number;
  template: number;
  reportTemplateFields: string;
  images: UploadFile[];
  galleryImages: ReportImages[];
}

type ValuesChangeType = { record: number; } | { template: number; } | { reportTemplateFields: string; }
  | { images: UploadFile[]; } | { galleryImages: ReportImages[]; };

interface QcReportsCreateContext {
  onSave: () => void;
  values: QcReportCreateParams;
  setValue: (value: ValuesChangeType) => void;
  isDisabled: boolean;
  setIsDisabled: (disabled: boolean) => void;
  qcReportCreate: FetchCreate<QcReportCreateSuccess, FetchError, FormData> | null;
}

export const initialValues: QcReportCreateParams = {
  record: 0,
  images: [],
  template: 0,
  reportTemplateFields: '[]',
  galleryImages: [],
};

const defaultValue: QcReportsCreateContext = {
  onSave: () => undefined,
  values: initialValues,
  setValue: () => undefined,
  isDisabled: false,
  setIsDisabled: () => undefined,
  qcReportCreate: null,
};

export const QcReportsCreateContext = createContext<QcReportsCreateContext>(defaultValue);
interface QcReportsCreateProviderProps {
  children: React.ReactNode;
}
const QcReportsCreateProvider: React.FC<QcReportsCreateProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const qcReportCreate = useQcReportCreate();
  const { id: recordId = '' } = useParams<{ id: string; }>();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const handleDisabledChange = (disabled: boolean) => {
    setIsDisabled(disabled);
  };

  const [values, setValues] = useState<QcReportCreateParams>({ ...initialValues, record: recordId ? +recordId : 0 });
  const handleValuesChange = (value: ValuesChangeType) => {
    setValues({ ...values, ...value });
  };

  useEffect(() => {
    handleDisabledChange(!(values.record && values.template && values.images.length));
  }, [values]);

  const onSave = () => {
    if (!isDisabled) {
      const formData = new FormData();

      formData.append('record', values.record.toString());
      formData.append('template', values.template.toString());
      formData.append('reportTemplateFields', values.reportTemplateFields);

      values.images.map(({ originFileObj }) => originFileObj)
        .forEach((image) => {
          if (image) {
            formData.append('images', image as File);
          }
        });

      if (values.galleryImages.length) {
        formData.append('galleryImages', JSON.stringify(values.galleryImages.map((el) => el.id)));
      }

      qcReportCreate.fetch(formData);
    }
  };

  useEffect(() => {
    if (qcReportCreate.data && !qcReportCreate.error) {
      message.success('Created!');
      setValues({ ...initialValues, record: recordId ? +recordId : 0 });
      navigate(`/reports/qc/${qcReportCreate.data.id}`, { replace: true });
    }
  }, [qcReportCreate.data]);

  useEffect(() => {
    if (qcReportCreate.error) {
      message.error(getMessageInError(qcReportCreate.error));
      qcReportCreate.clearError();
    }
  }, [qcReportCreate.error]);

  return (
    <QcReportsCreateContext.Provider
      value={{
        onSave,
        values,
        setValue: handleValuesChange,
        isDisabled,
        setIsDisabled: handleDisabledChange,
        qcReportCreate,
      }}
    >
      {children}
    </QcReportsCreateContext.Provider>
  );
};

export default QcReportsCreateProvider;

export const useContextQcReportCreate = (): QcReportsCreateContext => useContext(QcReportsCreateContext);
